import axios from "axios";
import store from "../store";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: function(status) {
    if (status === 401) {
      localStorage.removeItem("@Micasa420:token");

      window.location.reload();
    }
    return status >= 200 && status < 300; // default
  }
});

api.interceptors.request.use(
  config => {
    const { token } = store.getState().auth;
    const headers = { ...config.headers };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return { ...config, headers };
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default api;
