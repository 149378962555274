import { call, put } from "redux-saga/effects";
import { actions as toastrActions } from "react-redux-toastr";
import api from "../../services/api";

import { UploadAdActions } from "../ducks/upload-ad";

export function* doUploadAd(action) {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

  if (!acceptedImageTypes.includes(action.data.type)) {
    yield put(
      toastrActions.add({
        type: "error",
        message: "A imagem deve estar em formato gif, jpeg ou png"
      })
    );
    return yield put(UploadAdActions.uploadAdFailure());
  }

  const formData = new FormData();
  const maxSize = 2 * 1024 * 1024;

  if (action.data.size > maxSize) {
    yield put(
      toastrActions.add({
        type: "error",
        message: "O tamanho máximo permitido para este arquivo é de 2mb"
      })
    );
    return yield put(UploadAdActions.uploadAdFailure());
  }

  formData.append("main_pic", action.data);

  const response = yield call(api.post, `uploadMainPic/${action.ad}`, formData);

  if (response.data.url) {
    yield put(UploadAdActions.uploadAdSuccess(response.data));
  } else {
    yield put(UploadAdActions.uploadAdFailure());
  }

  return true;
}
