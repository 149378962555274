import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faUser,
  faPowerOff,
  faTachometerAlt,
  faCog,
  faFileAlt,
  faPaperPlane,
  faQuestionCircle,
  faUsers,
  faComments,
  faCalendarCheck,
  faEdit,
  faCamera,
  faTrash,
  faPlus,
  faMapMarkerAlt,
  faImages,
  faCheck,
  faCouch,
  faJoint,
  faDog,
  faMoneyBill,
  faInfoCircle,
  faImage,
  faPhoneSquareAlt
} from "@fortawesome/free-solid-svg-icons";

const Falib = library.add(
  faBars,
  faUser,
  faPowerOff,
  faTachometerAlt,
  faCog,
  faFileAlt,
  faPaperPlane,
  faQuestionCircle,
  faUsers,
  faComments,
  faCalendarCheck,
  faEdit,
  faCamera,
  faTrash,
  faPlus,
  faMapMarkerAlt,
  faImages,
  faCheck,
  faCouch,
  faJoint,
  faDog,
  faMoneyBill,
  faInfoCircle,
  faImage,
  faPhoneSquareAlt
);

export default Falib;
