import { all, takeLatest } from "redux-saga/effects";

import { signIn, facebookLogin, signOut, signUp } from "./auth";
import { getProfile, setProfile, deleteProfile } from "./profile";
import { doUploadAd } from "./upload-ad";
import { doUploadAvatar } from "./upload-avatar";
import { doUploadMedia, deleteMedia } from "./upload-media";
import { getAd, createAd, setAd } from "./ad";
import { getAdReview, setAdReview } from "./ad-review";
import { setSubscription, cancelSubscription } from "./subscription";

import { AuthTypes } from "../ducks/auth";
import { ProfileTypes } from "../ducks/profile";
import { UploadAdTypes } from "../ducks/upload-ad";
import { UploadAvatarTypes } from "../ducks/upload-avatar";
import { UploadMediaTypes } from "../ducks/upload-media";
import { AdTypes } from "../ducks/ad";
import { AdReviewTypes } from "../ducks/ad-review";
import { SubscriptionTypes } from "../ducks/subscription";

export default function* rootSaga() {
  return yield all([
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_OUT, signOut),
    takeLatest(AuthTypes.SIGN_UP_REQUEST, signUp),
    takeLatest(AuthTypes.FACEBOOK_LOGIN_REQUEST, facebookLogin),
    takeLatest(ProfileTypes.GET_PROFILE_REQUEST, getProfile),
    takeLatest(ProfileTypes.SET_PROFILE_REQUEST, setProfile),
    takeLatest(ProfileTypes.DELETE_PROFILE_REQUEST, deleteProfile),
    takeLatest(AdTypes.GET_AD_REQUEST, getAd),
    takeLatest(AdTypes.CREATE_AD_REQUEST, createAd),
    takeLatest(AdTypes.SET_AD_REQUEST, setAd),
    takeLatest(AdReviewTypes.GET_AD_REVIEW_REQUEST, getAdReview),
    takeLatest(AdReviewTypes.SET_AD_REVIEW_REQUEST, setAdReview),
    takeLatest(UploadAdTypes.UPLOAD_AD_REQUEST, doUploadAd),
    takeLatest(UploadAvatarTypes.UPLOAD_AVATAR_REQUEST, doUploadAvatar),
    takeLatest(UploadMediaTypes.UPLOAD_MEDIA_REQUEST, doUploadMedia),
    takeLatest(UploadMediaTypes.DELETE_MEDIA_REQUEST, deleteMedia),
    takeLatest(SubscriptionTypes.SET_SUBSCRIPTION_REQUEST, setSubscription),
    takeLatest(
      SubscriptionTypes.SET_CANCEL_SUBSCRIPTION_REQUEST,
      cancelSubscription
    )
  ]);
}
