import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import { cpfMask, phoneMask } from "../../utils/masks";

import { ProfileActions } from "../../store/ducks/profile";

import {
  MDBInput,
  MDBSpinner,
  MDBCol,
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBIcon
} from "mdbreact";

import { Container, Panel, Form, Section } from "./styles";

import validationSchema from "./validationSchema";

class Profile extends Component {
  state = {
    openDialog: false
  };

  componentDidMount() {
    this.props.getProfileRequest();
  }

  handleDialogOpen = () => {
    this.setState({ openDialog: true });
  };

  handleDialogClose = e => {
    e.preventDefault();
    this.setState({ openDialog: false });
  };

  handleDeleteAccount = async () => {
    const { deleteProfileRequest } = this.props;

    await deleteProfileRequest();
  };

  render() {
    const {
      profile,
      loadingProfile,
      handleSubmit,
      values,
      handleChange,
      errors
    } = this.props;

    if (!profile || loadingProfile) {
      return (
        <Container>
          <MDBSpinner />
        </Container>
      );
    }

    return (
      <Container>
        <Panel>
          <Form onSubmit={handleSubmit}>
            <Section>
              <span>Informações Básicas</span>
              <FontAwesomeIcon icon="info-circle" />
            </Section>
            <MDBContainer>
              <MDBRow>
                <MDBCol lg={6} md={6} sm={12}>
                  <MDBInput
                    type="text"
                    label="Nome"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                  />
                  {!!errors.first_name ? (
                    <strong style={{ color: "red" }}>
                      {errors.first_name}
                    </strong>
                  ) : (
                    ""
                  )}
                </MDBCol>
                <MDBCol lg={6} md={6} sm={12}>
                  <MDBInput
                    type="text"
                    label="Sobrenome"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  {!!errors.last_name ? (
                    <strong style={{ color: "red" }}>{errors.last_name}</strong>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol lg={6} md={6} sm={12}>
                  <MDBInput
                    type="text"
                    label="Telefone"
                    name="phone"
                    value={
                      values.phone ? phoneMask(values.phone) : values.phone
                    }
                    onChange={handleChange}
                  />
                </MDBCol>
                <MDBCol lg={6} md={6} sm={12}>
                  <MDBInput
                    type="text"
                    label="CPF"
                    name="document_number"
                    value={
                      values.document_number
                        ? cpfMask(values.document_number)
                        : values.document_number
                    }
                    onChange={handleChange}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <Section>
              <span>Dados de Acesso</span>
              <FontAwesomeIcon icon="lock" />
            </Section>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    type="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {!!errors.email ? (
                    <strong style={{ color: "red" }}>{errors.email}</strong>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol lg={6} md={6} sm={12}>
                  <MDBInput
                    type="password"
                    label="Senha"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {!!errors.password ? (
                    <strong style={{ color: "red" }}>{errors.password}</strong>
                  ) : (
                    ""
                  )}
                </MDBCol>
                <MDBCol lg={6} md={6} sm={12}>
                  <MDBInput
                    type="password"
                    label="Confirmação da Senha"
                    name="password_confirmation"
                    value={values.password_confirmation}
                    onChange={handleChange}
                  />
                  {!!errors.password_confirmation ? (
                    <strong style={{ color: "red" }}>
                      {errors.password_confirmation}
                    </strong>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row-reverse"
                  }}
                >
                  <Button
                    onClick={() => this.handleDialogOpen()}
                    color="secondary"
                    style={{ margin: "10px 0" }}
                  >
                    Deletar Conta
                  </Button>
                </div>
              </MDBRow>
            </MDBContainer>
            <div className="div-button">
              <MDBBtn type="submit" color="primary">
                <MDBIcon icon="check" className="mr-1" /> Salvar
              </MDBBtn>
            </div>
          </Form>
        </Panel>

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Tem certeza que deseja deletar sua conta?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Todo o conteúdo relacionado à sua conta será apagado do Micasa420
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={() => this.handleDeleteAccount()}
              color="primary"
              autoFocus
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

Profile.defaultProps = {
  profile: null
};

const mapStateToProps = state => ({
  profile: state.profile.data,
  loading: state.uploadAvatar.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ProfileActions
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ values, profile }) =>
      values || {
        id: profile ? profile._id : "",
        first_name: profile ? profile.first_name : "",
        last_name: profile ? profile.last_name : "",
        email: profile ? profile.email : "",
        phone: profile ? profile.phone : "",
        document_number: profile ? profile.document_number : "",
        password: "",
        password_confirmation: ""
      },

    handleSubmit: (values, { props }) => props.setProfileRequest(values),

    validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    validateOnBlur: false
  })
)(Profile);
