import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  uploadMediaRequest: ["data", "ad"],
  uploadMediaSuccess: ["data"],
  uploadMediaFailure: [],
  deleteMediaRequest: ["id"],
  deleteMediaSuccess: ["data"],
  deleteMediaFailure: [],
  resetUploadMedia: []
});

const INITIAL_STATE = {
  data: null,
  loading: false,
  loaded: false
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  loaded: true,
  data: action.data
});

const failure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  data: null
});

const reset = (state = INITIAL_STATE) => ({
  ...state,
  data: null
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPLOAD_MEDIA_REQUEST]: request,
  [Types.UPLOAD_MEDIA_SUCCESS]: success,
  [Types.UPLOAD_MEDIA_FAILURE]: failure,
  [Types.DELETE_MEDIA_REQUEST]: request,
  [Types.DELETE_MEDIA_SUCCESS]: success,
  [Types.DELETE_MEDIA_FAILURE]: failure,
  [Types.RESET_UPLOAD_MEDIA]: reset
});

export const UploadMediaTypes = Types;

export const UploadMediaActions = Creators;
