import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  position: fixed;
  margin-top: 43px;
  width: 210px;
  background: #fff;
  box-shadow: 1px 1px 17px -1px hsla(0, 0%, 63%, 0.69);
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1092px) {
    width: 50px;
  }

  @media (max-width: 769px) {
    display: none;
  }

  span.sub-title {
    display: inline-block;
    width: 100%;
    margin-left: 40px;
    margin-top: 15px;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 22px;
    letter-spacing: 1.11px;
    font-weight: 300;

    @media (max-width: 1092px) {
      display: none;
    }
  }

  a {
    display: inline-block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 12px;
    border-left: 3px solid #fff;
    color: #3d5170;
    text-decoration: none;
    align-items: center;

    &:hover {
      border-left: 3px solid #88334d;
      background-color: rgba(136, 51, 77, 0.1);
    }

    svg {
      width: 10%;
      font-size: 20px;
      margin-left: 15px;
      margin-right: 10px;
      align-items: center;
      vertical-align: middle;
      display: inline-block;

      @media (max-width: 1092px) {
        margin-left: 0;
        width: 100%;
      }
    }

    span {
      vertical-align: middle;
      display: inline-block;

      @media (max-width: 1092px) {
        display: none;
      }
    }
  }

  a.active {
    border-left: 3px solid #88334d;
    background-color: rgba(136, 51, 77, 0.1);

    svg {
      color: #88334d;
    }
  }

  a:last-child {
    margin-top: 20px;
    border-top: 0.1px solid #f6f6f6;
  }
`;
