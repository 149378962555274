import React from "react";

import { MDBContainer } from "mdbreact";

import TopBarStatic from "../../components/TopBarStatic";

import { Container } from "../../styles/pages";

import IMG_HEADER from "../../assets/images/header_home.png";

export default function Home(props) {
  return (
    <Container>
      <TopBarStatic />
      <div style={{ marginTop: "55px" }}>
        <img src={IMG_HEADER} alt="" />
      </div>

      <MDBContainer style={{ marginTop: "25px" }}>
        <h1>Política de Privacidade - Micasa420</h1>
        <p>
          Em 20 de abril de 2019, o Micasa420 lançou seu Aplicativo (App). Como
          resultado, atualizamos nossa política de privacidade. O Micasa420 sabe
          que você leva sua privacidade a sério. Nós também. Pretendemos ser
          claros a respeito de nossas práticas de dados e oferecer a você
          controle das informações que coletamos a seu respeito, algumas das
          quais podem identificá-lo ou por meio das quais você pode ser
          identificado.
        </p>
        <p>O Micasa420 oferece os seguintes serviços:</p>
        <p>
          1. Serviços ao usuário tomador, que incluem nosso site
          (www.micasa420.com) e nosso aplicativo de anúncios
          (app.micasa420.com); e
        </p>
        <p>
          2. Serviço ao usuário prestador, que incluem nosso site
          (www.micasa420.com) e nosso aplicativo de anunciantes
          (admin.micasa420.com)
        </p>
        <p>Esta Política de Privacidade se divide em três partes:</p>
        <p>
          Parte I se aplica a Dados sobre usuários de nossos Serviços (“Usuários
          do aplicativo de anúncios”).
        </p>
        <p>
          Parte II se aplica aos Dados fornecidos pelos usuários anunciantes de
          Serviços (“usuários do aplicativo de anunciantes”).
        </p>
        <p>
          Parte III se aplica a todos os dados que podemos coletar e continuar
          processando nas circunstâncias descritas nas Partes I e II
        </p>
        <h2>Parte I – Usuários do Micasa420</h2>
        <p>
          Abaixo encontram-se os tipos de Dados que coletamos sobre os Usuários
          do Micasa420 e as formas pelas quais podemos coletar, usar e
          compartilhar esses Dados.
        </p>
        <h3>Quais dados o Micasa420 coleta?</h3>
        <h4>Dados que você nos fornece:</h4>
        <p>
          Recebemos e armazenamos Dados que você nos fornece quando cria uma
          conta e usa nossos Serviços. Quando você cria uma conta, coletamos seu
          nome completo, endereço de e-mail, telefone, data de aniversário, a
          localização (quando autorizada). Conforme você interage conosco e usa
          nossos Serviços, também coletamos outros Dados fornecidos diretamente
          a nós, que nos Sites e Apps do Micasa420 podem incluir imagens que
          você optou por carregar em sua conta, quaisquer mensagens que você
          envia para outros usuários dos Apps, qualquer pesquisa por Hospedagens
          ou por outro serviço, suas curtidas, quem você segue, as avaliações,
          se você criou ou editou estabelecimentos, respostas de pesquisas,
          informações demográficas e, quaisquer outros Dados que você nos
          forneça.
        </p>
        <h4>Os Dados coletados quando você usa nossos Serviços:</h4>
        <p>
          Se você optar por compartilhar sua localização conosco, receberemos
          automaticamente os Dados de sua localização e/ou o endereço de IP
          sempre que você interagir com ou usar os Serviços (p. ex. abrir o App,
          visitar nossos Sites etc.). Se você optar por ativar a localização de
          fundo em nossos Apps, coletaremos Dados sobre você mesmo quando não
          estiver usando os Apps e os Apps estiverem fechados (conforme
          permitido pelas configurações do seu dispositivo).
        </p>
        <p>
          Nós usamos várias tecnologias para determinar sua localização exata,
          como serviços de localização de seu sistema operacional ou navegador,
          dados de sensor do seu dispositivo (p. ex., magnetrômetro, barômetro,
          giroscópios, acelerômetros, bússolas, dados de Bluetooth, dados de
          beacon, pontos de acesso Wi-Fi, dados de GPS e dados de torres de
          celulares) e outros Dados que possam nos ajudar a entender sua
          localização exata.
        </p>
        <p>
          Nós também poderemos coletar Dados adicionais sobre você quando usar
          os Serviços. Por exemplo, recebemos Dados:
        </p>
        <p>
          * coletados por cookies e outras tecnologias, como ID do cookie, ID de
          anúncios ou outra ID exclusiva de anúncios, beacons da web,
          armazenamento local e pixels. Você pode controlar determinados tipos
          de cookies nas configurações do navegador ou em outras ferramentas.
          Você pode ler nossa <a href="cookie_policies">Política de Cookies</a>
           para obter mais informações.
        </p>
        <p>
          Você pode desativar os serviços de localização a qualquer momento,
          acessando as configurações do dispositivo e desativando a localização
          de fundo. Para mais informações sobre localização, veja “Determinação
          e compartilhamento de localização” abaixo.
        </p>
        <h3>Como meus dados são usados?</h3>
        <p>
          Veja abaixo as maneiras como usamos os Dados dos usuários do
          Micasa420.
        </p>
        <h4>Para operar, manter e melhorar os nossos Serviços</h4>
        <p>
          Usamos os Dados para operar e manter os nossos Serviços, e para
          entendê-lo melhor e ajustar os nossos Serviços para você.
        </p>
        <p>
          Também usamos seus Dados para nos ajudar a mapear Localizações
          (conforme definido abaixo). Por exemplo, poderemos usar sinais de
          dispositivos para definir que determinado dispositivo está em
          determinado estabelecimento. Além disso, se você consentir em
          compartilhar dados de falhas (quando houver falhas nos Apps) conosco,
          poderemos usar esses dados para nos ajudar a analisar a causa da
          falha.
        </p>
        <h4>Comunicação com você</h4>
        <p>
          Usamos seus Dados para entrar em contato com você, inclusive por
          e-mail, ou em nossos Apps, sobre novas ofertas de produtos,
          atualizações dos Serviços ao Cliente ou qualquer outra coisa que possa
          interessá-lo. Para desabilitar o recebimento de comunicações
          promocionais e de marketing do Micasa420, siga as instruções contidas
          nas mensagens recebidas.
        </p>
        <p>
          Quando você desabilita comunicações promocionais e de marketing do
          Micasa420, observe que ainda poderemos enviar comunicações não
          promocionais, como as relacionadas à sua conta ou sobre qualquer
          relação comercial em andamento.
        </p>
        <h4>Proteção dos nossos direitos</h4>
        <p>
          Podemos usar seus Dados para proteger os direitos, a propriedade ou a
          segurança do Micasa420, os Serviços ao Cliente, nossos usuários e
          funcionários, ou qualquer terceiro. Por exemplo, poderemos usar seus
          Dados se precisarmos fazer cumprir ou aplicar nossos Termos de Serviço
          ou Políticas.
        </p>
        <h4>Serviços de Prestadores (anunciantes)</h4>
        <p>
          Onde o GDPR aplica-se ao processamento das suas PIIs, contamos com
          várias bases legais. Eles incluem:
        </p>
        <p>
          * quando o processamento é necessário para executar nosso contrato com
          você, como nossos Termos de Serviço. Isso pode incluir fornecer a você
          nossos Serviços ao Cliente ou adequar os Serviços ao Cliente às suas
          preferências;
        </p>
        <p>
          * quando você nos der seu consentimento, inclusive conforme descrito
          nesta Política de Privacidade. Você pode retirar seu consentimento
          para o processamento a qualquer momento, usando as configurações do
          seu dispositivo ou de sua conta;
        </p>
        <p>
          * quando tivermos uma obrigação legal para tal, conforme descrito
          nesta Política de Privacidade.
        </p>
        <h3>Quando meus dados são compartilhados?</h3>
        <p>
          Não alugamos ou vendemos dados sobre você, a menos que os dados sejam
          anonimizados, agregados ou em forma de pseudônimo, ou você tenha nos
          autorizado a compartilhá-los.
        </p>
        <h4>Dados que podem ser compartilhados com outros usuários</h4>
        <p>
          Quando você usa o App e Site do Micasa420, seu nome e suas avaliações
          tornam-se públicas dentro do aplicativo.
        </p>
        <h4>Dados que podem ser compartilhados com provedores de serviços</h4>
        <p>
          Às vezes podemos usar provedores de serviços, fornecedores e
          prestadores para providenciar ou prestar serviços em nosso nome. Esses
          incluem prestadores que hospedam nossos dados ou nos ajudam a oferecer
          um serviço melhor para você. Nesses casos, poderá ser necessário
          compartilhar suas PIIs (informações pessoalmente identificáveis) com
          eles. Nossos provedores de serviços, fornecedores e prestadores
          estarão sujeitos a obrigações contratuais vinculativas para: (i)
          processar apenas as PIIs de acordo com nossas instruções anteriores
          por escrito; (ii) não usar as PIIs para suas próprias finalidades; e
          (iii) usar medidas apropriadas para proteger a confidencialidade e a
          segurança das PIIs juntamente com quaisquer requisitos adicionais que
          temos ou que estão disponíveis sob as leis aplicáveis. Esses
          provedores de serviços, fornecedores e prestadores estão sob as
          seguintes categorias:
        </p>
        <p>
          * provedores de armazenamento em nuvem localizados nos Estados Unidos,
          como Amazon Web Services, Digital Ocean e MongoDB, que armazenam os
          dados pessoais que você nos fornece, e para serviços de recuperação de
          desastres;
        </p>
        <p>
          * provedores de analítica e motores de busca, como Google, que
          armazenam seus dados pessoais nos Estados Unidos para nos ajudar a
          melhorar e otimizar o site.
        </p>
        <h4>Quando necessário</h4>
        <p>
          Poderemos compartilhar seus Dados quando acreditarmos, de boa fé, que
          essa liberação é necessária para estar em conformidade com a lei ou
          com um processo legal, inclusive leis fora de seu país de residência;
          para proteger nossos direitos, a propriedade ou a segurança do
          Micasa420, nossos funcionários, os nossos serviços e mais.
        </p>
        <p>
          Poderemos compartilhar seus Dados como parte ou anteriormente a uma
          venda, fusão, consolidação, investimento, mudança no comando,
          transferência de ativos corporativos substanciais, reorganização,
          liquidação ou transações comerciais, ou eventos corporativos
          semelhantes.
        </p>
        <h3>Determinação e compartilhamento de localização</h3>
        <p>
          Alguns recursos e funcionalidades em nosso App pode precisar que você
          forneça sua localização. Caso seus serviços de localização estejam
          ativados, sempre que você abrir e usar ou interagir com nosso App,
          coletamos e usamos seus dados de localização (p. ex., latitude e
          longitude) para personalizar os nossos serviços de acordo com sua
          localização atual ou para convidar você para participar de uma
          pesquisa. Processaremos seus dados de localização apenas com sua
          permissão expressa. Se a localização de fundo persistente estiver
          ativada, concedendo permissões a nós por meio das configurações de
          localização e configurações de privacidade do seu dispositivo, iremos
          obter a localização do seu dispositivo mesmo se não estiver
          interagindo diretamente com o App. Seus dados de localização nunca são
          compartilhados com outras pessoas, exceto com seu consentimento ou
          conforme permitido nesta Política de Privacidade.
        </p>
        <h3>E os cookies e tecnologias semelhantes?</h3>
        <p>
          Poderemos usar tecnologias de cookies, pixels, beacons da web,
          armazenamento local e outros identificadores em nossos Sites, em
          nossos e-mails, e em nossos Apps. Usamos essas tecnologias para
          diversas finalidades, como autenticação, segurança, configurações de
          preferência, publicidade e fins analíticos. Às vezes, trabalhamos com
          anunciantes ou outros parceiros e provedores de serviços de
          publicidade para veicular anúncios para você nos nossos Serviços, o
          que pode ser relevante para você de acordo com a sua interação nos
          nossos Serviços.
        </p>
        <p>
          Você pode controlar os cookies pelas configurações do navegador ou com
          outras ferramentas. Se você rejeitar cookies, isso poderá afetar a
          disponibilidade e a funcionalidade dos Serviços ao Cliente. Para obter
          mais informações, incluindo os tipos de cookies e tecnologias
          similares encontrados no Micasa420, leia nossa{" "}
          <a href="cookie_policies">Política de Cookies</a>.
        </p>
        <h3>Como acesso ou excluo meus dados?</h3>
        <p>
          Você pode acessar e atualizar seus Dados a qualquer momento na guia
          “Perfil” da conta do Micasa420, e para os anunciantes na guia “Meus
          Dados”. Você também pode solicitar uma cópia dos Dados ao encaminhando
          um e-mail para app.micasa420@gmail.com.
        </p>
        <p>
          Você também pode excluir seus Dados e sua conta em nossos Sites a
          qualquer momento, clicando no link Excluir sua conta na guia “Perfil”
          da conta do Micasa420, e para os anunciantes na guia “Meus Dados”. Se
          excluir seus Dados, seu perfil será removido dos nossos Serviços e
          você não poderá mais acessar esses Dados. Não seremos capazes de
          restaurar sua conta depois da exclusão. A exclusão da sua conta poderá
          não ser imediata. Cópias residuais das suas PIIs ou das suas
          publicações podem continuar no backup de mídia por um curto período de
          tempo após a exclusão.
        </p>
        <p>
          Se tiver perguntas sobre como acessar, atualizar ou excluir os seus
          Dados, envie um e-mail para app.micasa420@gmail.com
        </p>
        <h3>Posso desabilitar o recebimento?</h3>
        <h4>
          Como desabilitar o recebimento de e-mails promocionais e de marketing
        </h4>
        <p>
          é possível desabilitar o recebimento de e-mails promocionais ou de
          marketing a qualquer momento, no rodapé de qualquer e-mail de
          marketing ou promocional. Observe que não é possível desabilitar o
          recebimento de avisos legais ou de serviços.
        </p>
        <h4>
          Como remover o compartilhamento de localização em segundo plano?
        </h4>
        <p>
          Caso use nossos aplicativos e tenha optado por compartilhar sua
          localização em segundo plano conosco, você poderá remover a permissão
          a qualquer momento, ao entrar nas configurações operacionais do
          dispositivo e ativar ou desativar o compartilhamento em segundo plano.
        </p>
        <h2>Parte II - Usuários finais parceiros</h2>
        <p>
          Abaixo há formas pelas quais podemos coletar, usar e compartilhar os
          Dados sobre Usuários finais parceiros.
        </p>
        <h2>Parte III – Indivíduos descritos em todas as Partes I e II.</h2>
        <p>
          A Parte III se aplica a Dados sobre indivíduos que coletamos nas
          circunstâncias descritas em todas as Partes I e II.
        </p>
        <h3>Seus direitos legais</h3>
        <p>
          Sujeito às leis aplicáveis, você poderá ter diversos direitos
          relacionados ao processamento de PIIs, inclusive:
        </p>
        <p>
          * o direito de solicitar acesso a originais ou a cópias de PIIs que
          processamos ou controlamos, junto a informações relacionadas à
          natureza, ao processamento e à divulgação dessas PIIs;
        </p>
        <p>
          * o direito de solicitar a nova certificação de quaisquer imprecisões
          em suas PIIs que processamos ou controlamos;
        </p>
        <p>* o direito de solicitar, de forma legítima:</p>
        <p>** a exclusão de suas PIIs que processamos ou controlamos; ou</p>
        <p>
          ** as restrições de processamento de suas PIIs que processamos ou
          controlamos;
        </p>
        <p>
          * o direito de se opor, de forma legítima, ao processamento de suas
          PIIs por nós ou em nosso nome;
        </p>
        <p>
          * o direito de transferir suas PIIs que processamos ou controlamos
          para outro controlador de dados, na extensão aplicável;
        </p>
        <p>* onde processamos suas PIIs de acordo com seu consentimento e</p>
        <p>
          * o direito de fazer uma reclamação com uma Autoridade de proteção de
          dados sobre as suas PIIs por nós ou em nosso nome.
        </p>
        <p>* Isso não afeta seus direitos legais.</p>
        <p>
          * Para exercer um ou mais desses direitos ou tirar alguma dúvida sobre
          esses direitos ou qualquer outra provisão dessa Política de
          Privacidade ou sobre o processamento de PIIs, use as informações de
          contato fornecidas na seção Fale conosco.
        </p>
        <p>
          * Para manter o nosso compromisso de proteger a privacidade das suas
          PIIs, processaremos somente solicitações, incluindo, mas não limitadas
          a, solicitações de acesso a dados, solicitações de eliminação de
          dados, solicitações de portabilidade de dados, se conseguirmos
          verificar ou autenticar sua identidade. Se não for possível verificar
          ou autenticar sua identidade, não poderemos atender à sua solicitação.
          Se você não for um usuário final dos Apps ou não for usuário final de
          um App de Parceiros, não poderemos atender à sua solicitação, pois não
          há como, no momento, verificar ou autenticar a sua identidade com
          precisão.
        </p>
        <h3>Segurança</h3>
        <p>
          Usamos medidas de segurança físicas, técnicas e lógicas padrão do
          setor para proteger seus Dados. Nós nos esforçamos para proteger seus
          Dados de forma a assegurar que eles sejam mantidos em segurança. No
          entanto, não é possível garantir a segurança dos Dados em uma conta
          contra entrada ou uso não autorizado, falha de hardware ou software e
          outros fatores.
        </p>
        <p>
          Sua conta é protegida por uma senha escolhida por você. Recomendamos
          usar uma senha que seja exclusiva da conta do Micasa420. Se você usar
          nossos Serviços em redes públicas ou compartilhadas (p.ex. wifi
          pública), não podemos garantir a segurança de sua conta.
        </p>
        <h3>Retenção de PIIs</h3>
        <p>
          Os critérios para determinar a quantidade de tempo que manteremos as
          suas PIIs são os seguintes: vamos reter cópias das suas PIIs apenas
          pelo tempo que você mantiver sua conta, ou conforme necessário em
          conexão com os objetivos definidos nesta Política de Privacidade, a
          não ser que as leis aplicáveis exijam um tempo maior de retenção. Além
          disso, poderemos reter suas PIIs pelo tempo necessário para
          estabelecer, exercer ou defender os direitos legais.
        </p>
        <h3>Usuários internacionais</h3>
        <p>
          Nossos Apps e todos os nossos dados, inclusive seus Dados, ficam
          hospedados em servidores nos Estados Unidos. Se você estiver usando ou
          acessando os Serviços ao Cliente ou os Serviços Empresariais de fora
          dos Estados Unidos, seus Dados serão transferidos, processados e
          armazenados nos Estados Unidos. Como consequência, os governos
          estaduais e federal, os tribunais e as agências reguladoras ou forças
          policiais dos Estados Unidos poderão obter seus Dados por meio de leis
          aplicáveis nos Estados Unidos. As leis dos Estados Unidos podem não
          oferecer as mesmas proteções de privacidade de sua jurisdição.
        </p>
        <h3>Alterações nesta Política de Privacidade</h3>
        <p>
          O Micasa420 pode, ocasionalmente, alterar esta Política de
          Privacidade. Incentivamos você a verificar esta página regularmente
          para revisar possíveis alterações que podemos fazer de acordo com esta
          Política de Privacidade. Se fizermos modificações significativas na
          maneira com usamos seus Dados, nós o notificaremos publicando um aviso
          em nossos Sites ou Apps ou enviando um e-mail (se tivermos seu
          e-mail). Se permitido por lei, os usuários estão sujeitos a qualquer
          alteração desta Política de Privacidade quando usam nossos Serviços
          depois da primeira publicação dessas alterações.
        </p>
        <h2>Contato.</h2>
        <p>
          Você pode entrar em contato com o Micasa420 pelo e-mail:
          app.micasa420@gmail.com ou no endereço: Alameda dos Vinháticos, nº270,
          Sala 2, Mosteiro de Itaici, Indaiatuba-SP- Brasil. CEP. 13341-040.
        </p>
      </MDBContainer>
    </Container>
  );
}
