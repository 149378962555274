import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const colorChange = keyframes`
  from {
    background-color: #EB73B9;
  }
  to {
    background-color: #17EAD9;
  }
`;

const SearchField = styled.fieldset`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
  height: 40px;

  .icons-container {
    position: absolute;
    top: 3px;
    right: 0;
    width: 35px;
    height: 35px;
    overflow: hidden;
  }

  .icon-close {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 75%;
    height: 75%;
    opacity: 0;
    cursor: pointer;
    transform: translateX(-200%);
    border-radius: 50%;
    transition: opacity 0.25s ease,
      transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
    &:before {
      content: "";
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border: 2px solid transparent;
      border-top-color: #6078ea;
      border-left-color: #6078ea;
      border-bottom-color: #6078ea;
      transition: opacity 0.2s ease;
    }
    .x-up {
      position: relative;
      width: 100%;
      height: 50%;
      &:before {
        content: "";
        position: absolute;
        bottom: 2px;
        left: 3px;
        width: 50%;
        height: 2px;
        background-color: #6078ea;
        transform: rotate(45deg);
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 2px;
        right: 0px;
        width: 50%;
        height: 2px;
        background-color: #6078ea;
        transform: rotate(-45deg);
      }
    }
    .x-down {
      position: relative;
      width: 100%;
      height: 50%;
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 4px;
        width: 50%;
        height: 2px;
        background-color: #6078ea;
        transform: rotate(-45deg);
      }
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        right: 1px;
        width: 50%;
        height: 2px;
        background-color: #6078ea;
        transform: rotate(45deg);
      }
    }
    .is-type & {
      &:before {
        opacity: 1;
        animation: ${spin} 0.85s infinite;
      }
      .x-up {
        &:before,
        &:after {
          animation: ${colorChange} 0.85s infinite;
        }
        &:after {
          animation-delay: 0.3s;
        }
      }
      .x-down {
        &:before,
        &:after {
          animation: ${colorChange} 0.85s infinite;
        }
        &:before {
          animation-delay: 0.2s;
        }
        &:after {
          animation-delay: 0.1s;
        }
      }
    }
  }

  .icon-search {
    position: relative;
    top: 5px;
    left: 8px;
    width: 50%;
    height: 50%;
    opacity: 1;
    border-radius: 50%;
    border: 3px solid #c7d0f8;
    transition: opacity 0.25s ease,
      transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
    &:after {
      content: "";
      position: absolute;
      bottom: -9px;
      right: -2px;
      width: 4px;
      border-radius: 3px;
      transform: rotate(-45deg);
      height: 10px;
      background-color: #c7d0f8;
    }
  }

  .field {
    border: 0;
    width: 100%;
    height: 100%;
    padding: 10px 35px 10px 20px;
    background: white;
    border-radius: 3px;
    box-shadow: 0px 8px 15px rgba(75, 72, 72, 0.1);
    transition: all 0.4s ease;
    &:focus {
      outline: none;
      box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
      + .icons-container {
        .icon-close {
          opacity: 1;
          transform: translateX(0);
        }
        .icon-search {
          opacity: 0;
          transform: translateX(200%);
        }
      }
    }
  }
`;

export default SearchField;
