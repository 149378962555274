import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { toastr } from "react-redux-toastr";

import { MDBBtn, MDBSpinner, MDBInput } from "mdbreact";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthActions } from "../../../store/ducks/auth";

import {
  Container,
  SignForm,
  Logo,
  Wrapper,
  ErrorBox,
  FormGroup
} from "../styles";

import * as IMAGES from "../../../assets/images";

const label_age = (
  <span className="label-check">Confirmo que sou maior de 18 anos</span>
);

const label_termos = (
  <span className="label-check">
    Declaro que li e concordo com os &nbsp;
    <Link to="/terms_and_conditions" target="_blank">
      Termos de Uso
    </Link>{" "}
    e &nbsp;
    <Link to="/privacy_policies" target="_blank">
      Política de Privacidade
    </Link>
  </span>
);

class SignUp extends Component {
  static propTypes = {
    signUpRequest: PropTypes.func.isRequired
  };

  state = {
    loading: false,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    error_first_name: false,
    error_last_name: false,
    error_email: false,
    error_pass: false,
    age: false,
    terms: false
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      error_first_name: false,
      error_last_name: false,
      error_email: false,
      error_pass: false,
      loading: true
    });

    const {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      age,
      terms
    } = this.state;
    const { signUpRequest } = this.props;

    if (
      !first_name ||
      !last_name ||
      !email ||
      !password ||
      !password_confirmation
    ) {
      if (!first_name) {
        this.setState({ error_first_name: true });
      }
      if (!last_name) {
        this.setState({ error_last_name: true });
      }
      if (!email) {
        this.setState({ error_email: true });
      }
      if (!password || !password_confirmation) {
        this.setState({ error_pass: true });
      }
    } else if (!age) {
      toastr.error("", "É necessário ter mais de 18 anos para se cadastrar");
    } else if (!terms) {
      toastr.error(
        "",
        "É necessário ler e concordar com os termos de uso e política de privacidade para se cadastrar "
      );
    } else {
      await signUpRequest(
        first_name,
        last_name,
        email,
        password,
        password_confirmation
      );
    }

    this.setState({ loading: false });
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      error_first_name,
      error_last_name,
      error_email,
      error_pass,
      age,
      terms
    } = this.state;

    return (
      <Container>
        <Wrapper>
          <Logo href="https://micasa420.com">
            <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
          </Logo>
          <div className="form-wrapper">
            <SignForm onSubmit={this.handleSubmit}>
              <h1>Criar conta</h1>

              <FormGroup>
                <label htmlFor="Nome">
                  Nome
                  <input
                    placeholder="Digite seu primeiro nome"
                    id="first_name"
                    name="first_name"
                    value={first_name}
                    onChange={this.handleInputChange}
                  />
                </label>
              </FormGroup>
              {error_first_name ? (
                <ErrorBox>
                  <span>Esse campo é obrigatório</span>
                </ErrorBox>
              ) : (
                ""
              )}
              <FormGroup>
                <label htmlFor="Sobrenome">
                  Sobrenome
                  <input
                    placeholder="Digite seu último nome"
                    id="last_name"
                    name="last_name"
                    value={last_name}
                    onChange={this.handleInputChange}
                  />
                </label>
              </FormGroup>
              {error_last_name ? (
                <ErrorBox>
                  <span>Esse campo é obrigatório</span>
                </ErrorBox>
              ) : (
                ""
              )}
              <FormGroup>
                <label htmlFor="email">
                  E-mail
                  <input
                    type="email"
                    placeholder="Digite seu e-mail"
                    id="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                </label>
              </FormGroup>
              {error_email ? (
                <ErrorBox>
                  <span>Esse campo é obrigatório</span>
                </ErrorBox>
              ) : (
                ""
              )}
              <FormGroup>
                <label htmlFor="password">
                  Senha
                  <input
                    type="password"
                    placeholder="Digite sua senha"
                    id="password"
                    name="password"
                    value={password}
                    onChange={this.handleInputChange}
                  />
                </label>
              </FormGroup>

              <FormGroup>
                <label htmlFor="password_confirmation">
                  Confirmação da senha
                  <input
                    type="password"
                    placeholder="Digite sua senha"
                    id="password_confirmation"
                    name="password_confirmation"
                    value={password_confirmation}
                    onChange={this.handleInputChange}
                  />
                </label>
              </FormGroup>
              {error_pass ? (
                <ErrorBox>
                  <span>As senhas não coincidem</span>
                </ErrorBox>
              ) : (
                ""
              )}
              <MDBInput
                label={label_age}
                type="checkbox"
                id="age"
                name="age"
                value={age}
                onChange={this.handleInputChange}
              />
              <MDBInput
                label={label_termos}
                type="checkbox"
                id="terms"
                name="terms"
                value={terms}
                onChange={this.handleInputChange}
              />
              <MDBBtn
                type="submit"
                color="teal darken-4"
                disabled={this.state.loading}
              >
                {this.state.loading ? <MDBSpinner /> : "Criar"}
              </MDBBtn>

              <Link to="/">Já tenho cadastro</Link>
            </SignForm>
          </div>
        </Wrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(null, mapDispatchToProps)(SignUp);
