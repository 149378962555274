import React from "react";

import { MDBContainer } from "mdbreact";

import TopBarStatic from "../../components/TopBarStatic";

import { Container } from "../../styles/pages";

import IMG_HEADER from "../../assets/images/header_home.png";

export default function Home(props) {
  return (
    <Container>
      <TopBarStatic />
      <div style={{ marginTop: "55px" }}>
        <img src={IMG_HEADER} alt="" />
      </div>

      <MDBContainer
        style={{
          marginTop: "25px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <h1>Sua conta foi deletada com sucesso.</h1>
        <p>
          Caso volte a ter interesse em nossos serviços, basta se cadastrar
          novamente no nosso site :)
        </p>
        <a href="https://micasa420.com">Voltar à Home</a>
      </MDBContainer>
    </Container>
  );
}
