import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .tabs {
    letter-spacing: 0.05em;
  }

  .nav-link {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgb(30, 59, 25) !important;
    background-color: transparent !important;

    span {
      margin-left: 10px;
      @media (max-width: 992px) {
        display: none;
      }
    }

    &:hover {
      font-weight: 600;
    }

    &.active {
      font-weight: 600;
    }
  }

  .nav-link.nav-active.active {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: rgb(30, 59, 25);
    transition: all 1s;
    border-radius: 0.25rem;
  }

  .nav-item:last-child {
    .nav-link {
      color: #fff;
    }
  }
`;
