import {
  Dashboard,
  Person,
  List,
  PowerSettingsNew,
  Stars,
  KeyboardArrowDown,
  LocalBar,
  Hotel,
  Work,
  Create,
  Edit,
  Delete,
  ArrowDropDown,
  ArrowDropUp,
  LocalHospital,
  Category,
  Explore
} from "@material-ui/icons";

export const DashboardIcon = Dashboard;

export const PersonIcon = Person;

export const ListIcon = List;

export const LogoutIcon = PowerSettingsNew;

export const ReviewIcon = Stars;

export const ArrowDownIcon = KeyboardArrowDown;

export const HotelIcon = Hotel;

export const ExperienceIcon = LocalBar;

export const BusinessIcon = Work;

export const CreateIcon = Create;

export const EditIcon = Edit;

export const DeleteIcon = Delete;

export const DownIcon = ArrowDropDown;

export const UpIcon = ArrowDropUp;

export const MedicalIcon = LocalHospital;

export const OthersIcon = Category;

export const ExploreIcon = Explore;
