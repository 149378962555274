const getSubTypes = (type, subType) => {
  if (type === "experience") {
    return [
      {
        index: 0,
        value: "Viagem",
        checked: subType === "Viagem"
      },
      {
        index: 1,
        value: "Jantar",
        checked: subType === "Jantar"
      },
      {
        index: 2,
        value: "Workshop",
        checked: subType === "Workshop"
      },
      {
        index: 3,
        value: "Festa",
        checked: subType === "Festa"
      },
      {
        index: 4,
        value: "Evento",
        checked: subType === "Evento"
      },
      {
        index: 5,
        value: "Feira",
        checked: subType === "Feira"
      },
      {
        index: 6,
        value: "Guia Turístico",
        checked: subType === "Guia Turístico"
      },
      {
        index: 8,
        value: "Outros",
        checked: subType === "Outros"
      }
    ];
  }

  if (type === "business") {
    return [
      {
        index: 0,
        value: "Headshop",
        checked: subType === "Headshop"
      },
      {
        index: 1,
        value: "Growshop",
        checked: subType === "Growshop"
      },
      {
        index: 2,
        value: "Cafeteria",
        checked: subType === "Cafeteria"
      },
      {
        index: 3,
        value: "Tabacaria",
        checked: subType === "Tabacaria"
      },
      {
        index: 4,
        value: "Dispensário",
        checked: subType === "Dispensário"
      },
      {
        index: 5,
        value: "Vestuário",
        checked: subType === "Vestuário"
      },
      {
        index: 6,
        value: "Marca",
        checked: subType === "Marca"
      },
      {
        index: 7,
        value: "Outros",
        checked: subType === "Outros"
      }
    ];
  }

  if (type === "medical") {
    return [
      {
        index: 0,
        value: "Médico",
        checked: subType === "Médico"
      },
      {
        index: 1,
        value: "Clínica",
        checked: subType === "Clínica"
      },
      {
        index: 1,
        value: "Associação Medicinal",
        checked: subType === "Associação Medicinal"
      },
      {
        index: 3,
        value: "Farmácia",
        checked: subType === "Farmácia"
      },
      {
        index: 4,
        value: "Outros",
        checked: subType === "Outros"
      }
    ];
  }

  if (type === "others") {
    return [
      {
        index: 0,
        value: "Associação",
        checked: subType === "Associação"
      },
      {
        index: 1,
        value: "Coletivo",
        checked: subType === "Coletivo"
      },
      {
        index: 2,
        value: "ONG",
        checked: subType === "ONG"
      },
      {
        index: 3,
        value: "Clube",
        checked: subType === "Clube"
      },
      {
        index: 4,
        value: "Palestrante",
        checked: subType === "Palestrante"
      },
      {
        index: 5,
        value: "Consultor",
        checked: subType === "Consultor"
      },
      {
        index: 6,
        value: "Outros",
        checked: subType === "Outros"
      }
    ];
  }
};

export default getSubTypes;
