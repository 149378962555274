import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  getAdReviewRequest: ["id"],
  getAdReviewSuccess: ["data"],
  getAdReviewComplete: [],
  setAdReviewRequest: ["id", "reply"],
  setAdReviewSuccess: ["data"],
  setAdReviewComplete: [],
  setAdReviewFailure: []
});

const INITIAL_STATE = {
  id: "",
  reply: "",
  data: null,
  loading: false
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  data: action.data
});

const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false
});

const endLoading = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AD_REVIEW_REQUEST]: request,
  [Types.GET_AD_REVIEW_SUCCESS]: success,
  [Types.GET_AD_REVIEW_COMPLETE]: endLoading,
  [Types.SET_AD_REVIEW_REQUEST]: request,
  [Types.SET_AD_REVIEW_SUCCESS]: success,
  [Types.SET_AD_REVIEW_COMPLETE]: endLoading,
  [Types.SET_AD_REVIEW_FAILURE]: failure
});

export const AdReviewTypes = Types;

export const AdReviewActions = Creators;
