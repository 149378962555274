import React, { Component } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBContainer } from "mdbreact";

import logo from "../../assets/images/Micasa420 ROXO.png";

class TopBarStatic extends Component {
  render() {
    return (
      <MDBNavbar color="white" light fixed="top">
        <MDBContainer>
          <MDBNavbarBrand>
            <a href="https://micasa420.com">
              <img
                src={logo}
                alt="Micasa420 logo"
                width="150"
                style={{
                  maxWidth: "100%",
                  verticalAlign: "middle",
                  display: "inline-block"
                }}
              />
            </a>
          </MDBNavbarBrand>
        </MDBContainer>
      </MDBNavbar>
    );
  }
}

export default TopBarStatic;
