import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { MDBBtn, MDBSpinner } from "mdbreact";

import {
  Container,
  SignForm,
  Logo,
  Wrapper,
  ErrorBox,
  FormGroup
} from "../styles";

import * as IMAGES from "../../../assets/images";

class ForgotPass extends Component {
  state = {
    loading: false,
    email: "",
    error: false,
    emailSent: false,
    nullError: false
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true });

    const { email } = this.state;

    if (email === "") {
      this.setState({
        error: false,
        nullError: true
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/forgotPassword`, {
          email
        })
        .then(response => {
          this.setState({
            error: false,
            emailSent: true,
            nullError: false,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            error: true,
            emailSent: false,
            nullError: false,
            loading: false
          });
        });
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value, nullError: false });
  };

  render() {
    const { email, error, emailSent, nullError } = this.state;

    if (emailSent) {
      return (
        <Container>
          <Wrapper>
            <Logo href="https://micasa420.com">
              <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
            </Logo>
            <SignForm onSubmit={this.handleSubmit}>
              <h3>
                O link para criação da nova senha foi enviado ao seu e-mail com
                sucesso!
              </h3>

              <Link to="/">Voltar à página inicial</Link>
            </SignForm>
          </Wrapper>
        </Container>
      );
    }

    return (
      <Container>
        <Wrapper>
          <Logo href="https://micasa420.com">
            <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
          </Logo>
          <SignForm onSubmit={this.handleSubmit}>
            <h3>Informe seu e-mail para receber o link de criação da senha</h3>

            <FormGroup>
              <label htmlFor="email">
                E-mail
                <input
                  type="email"
                  placeholder="Digite seu e-mail"
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.handleInputChange}
                />
              </label>
            </FormGroup>
            {nullError ? (
              <ErrorBox>
                <span>O e-mail é obrigatório</span>
              </ErrorBox>
            ) : (
              ""
            )}
            <MDBBtn
              type="submit"
              color="teal darken-4"
              disabled={this.state.loading}
            >
              {this.state.loading ? <MDBSpinner /> : "Enviar"}
            </MDBBtn>
            {error ? (
              <ErrorBox>
                <span>
                  Ocorreu um erro ao enviar o e-mail, tente novamente mais tarde
                </span>
              </ErrorBox>
            ) : (
              ""
            )}
            <Link to="/signup">
              <span>Não tem cadastro ainda?</span> Crie sua conta gratuitamente
            </Link>
          </SignForm>
        </Wrapper>
      </Container>
    );
  }
}

export default ForgotPass;
