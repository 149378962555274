import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  div.buttons {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    @media (max-width: 500px) {
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 10px;
    }

    span {
      margin-left: 10px;
    }

    .btn {
      width: 165px;
    }
  }

  .tab-content {
    padding: 0;
    padding-top: 2rem;
  }

  .md-form {
    margin-top: 0.8rem;
  }
`;

export const Panel = styled.div`
  margin: 1px 0;
  width: 100%;
  background: #fff;
  border-top: none;
  display: flex;

  @media (max-width: 838px) {
    width: 98%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  label.text-primary {
    font-size: 1em;

    color: #757575 !important;
  }

  .switch {
    margin-bottom: 20px;
  }
  .switch label input[type="checkbox"]:checked + .lever {
    background-color: #bbdefb !important;
  }
  .switch label input[type="checkbox"]:checked + .lever:after {
    background-color: #0d47a1 !important;
  }

  div.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  div.wrapper-main-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    span {
      text-align: center;
      font-size: 0.65em;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  div.main-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 250px;

    @media (max-width: 769px) {
      height: 225px;
      width: 225px;
    }

    input.upload-avatar {
      display: none;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 230px;
      object-fit: cover;
      border-radius: 5px;
    }

    div.placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      transition: 0.3s ease;
      background-color: #fff;
      border: 2px dashed rgba(38, 38, 38, 0.4);
      border-radius: 5px;
    }

    div.overlay {
      position: absolute;
      z-index: 1;
    }

    div.icon {
      cursor: pointer;
      color: rgba(38, 38, 38, 0.4);
      font-size: 45px;
      text-align: center;

      svg:hover {
        color: rgb(38, 38, 38);
      }
    }
  }

  div.fields {
    display: flex;
    flex-direction: column;
    width: 100%;

    .native-select {
      font-size: ${props => (props.small ? "12px" : "14px")};
      background: #ffffff;
      width: 100%;

      &::before {
        border-bottom: 2px solid rgba(63, 81, 181, 0.3);
      }
      &::after {
        border-bottom: 2px solid rgba(63, 81, 181, 0.8);
      }
    }
  }

  div.wrapper-img-upload {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;

    ul {
      width: 100%;
    }

    div.img-placeholder {
      height: 100%;
      width: 100%;
      transition: 0.3s ease;
      background-color: #fff;
      border: 2px dashed rgba(38, 38, 38, 0.4);
      border-radius: 5px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      div.icon {
        cursor: pointer;
        color: rgba(38, 38, 38, 0.4);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        svg {
          font-size: 45px;
        }

        span {
          font-size: 1em;
          text-align: center;
        }

        &:hover {
          color: rgb(38, 38, 38);
        }
      }
    }

    .img-tile-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .img-list {
      height: 250px;

      width: 25%;

      @media (max-width: 1300px) {
        width: 33% !important;
      }

      @media (max-width: 1100px) {
        width: 50% !important;
      }

      @media (max-width: 550px) {
        width: 100% !important;
      }
    }
  }

  div.div-map {
    display: flex;
    flex-direction: column;
    width: 100%;

    input#input-places {
      margin-top: 5px;
      width: 100%;
      background: none;
      border-style: none;
      border-bottom: 2px solid rgba(63, 81, 181, 0.3);
      padding: 10px;
      font-size: ${props => (props.small ? "12px" : "14px")};
      background: #ffffff;
      color: rgba(63, 81, 181, 0.8);

      &:hover,
      &:focus,
      &:active {
        border-bottom: 2px solid rgba(63, 81, 181, 0.8);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        border-style: none;
        border-bottom: 2px solid rgba(63, 81, 181, 0.8);
        box-shadow: none;
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
        -webkit-text-fill-color: rgba(63, 81, 181, 0.8) !important;
      }
    }
  }
`;

export const FormGroup = styled.div`
  padding: 0 10px;
  width: 100%;
  font-size: 12px;

  & label {
    width: 100%;
    display: block;
    color: #757575 !important;

    text-align: left;
    margin-bottom: 10px;
  }

  textarea {
    width: 100%;
  }
`;

export const Section = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(63, 81, 181, 0.2),
    0px 4px 5px 0px rgba(63, 81, 181, 0.14),
    0px 1px 10px 0px rgba(63, 81, 181, 0.12);

  background: rgb(63, 81, 181);
  color: #fff;
  font-size: 1.2em;
  font-weight: 800;
  letter-spacing: 0.15em;

  span {
    width: 80%;
  }

  svg {
    font-size: 1.5em;
  }

  @media (max-width: 769px) {
    margin-left: 0;

    font-size: 0.85em;
    svg {
      font-size: 1.25em;
    }
  }
`;

export const WrapperMap = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  .select-dropdown {
    font-size: 0.88em !important;
  }

  .label-price {
    font-size: 0.95em;
    margin: 5px 0;
    color: #757575;
  }
  .input-price {
    background: none;
    border-style: none;
    border-bottom: 2px solid #ced4da;
    background: #ffffff;
    width: 100%;
    margin-bottom: 10px;

    &:hover,
    &:focus,
    &:active {
      border-bottom: 1px solid #4285f4;
    }
  }
`;

export const StatusContainer = styled.div`
  padding: 20px 50px;
  width: 65%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1250px) {
    width: 80%;
  }

  @media (max-width: 780px) {
    width: 100%;
    padding: 20px;
  }

  div.status-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 480px) {
      width: 100%;

      margin-bottom: 15px;
    }

    label {
      font-size: 0.9em;
      font-weight: 600;
      text-transform: uppercase;
    }

    span.active {
      text-transform: uppercase;
      font-size: 1.2em;
      color: ${props => (props.active ? "rgb(0, 229, 52)" : "rgb(255, 3, 49)")};
      text-shadow: ${props =>
        props.active
          ? "0px 0px 6px rgba(0, 229, 52, 0.7)"
          : "0px 0px 6px rgba(255, 125, 149, 0.7)"};
      margin-bottom: 10px;
      text-align: center;

      @media (max-width: 480px) {
        margin-bottom: 0;
      }

      @media (max-width: 550px) {
        font-size: 0.9em;
      }
    }

    span.premium {
      text-transform: uppercase;
      font-size: 1.2em;
      color: ${props =>
        props.premium ? "rgb(0, 229, 52)" : "rgb(0, 32, 237)"};
      text-shadow: ${props =>
        props.premium
          ? "0px 0px 6px rgba(0, 229, 52, 0.7)"
          : "0px 0px 6px rgba(97, 118, 255, 0.7)"};
      margin-bottom: 10px;
      text-align: center;
      @media (max-width: 550px) {
        font-size: 0.9em;
      }
    }
  }
`;

export const PremiumSectionAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;

  span {
    font-size: 1em;
    color: rgb(100, 100, 100);

    @media (max-width: 992px) {
      font-size: 0.88em;
    }
  }
`;
