import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  DashboardIcon,
  PersonIcon,
  LogoutIcon,
  HotelIcon,
  BusinessIcon,
  ExperienceIcon,
  MedicalIcon,
  OthersIcon,
  ExploreIcon
} from "../../utils/materialiconslib";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthActions } from "../../store/ducks/auth";

import { Container } from "./styles";

class Sidebar extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired
  };

  handleLogout = e => {
    e.preventDefault();

    const { signOut } = this.props;

    signOut();
  };

  render() {
    return (
      <Container>
        <NavLink to="/dashboard" activeClassName="active">
          <DashboardIcon />
          <span>Dashboard</span>
        </NavLink>
        <NavLink to="/profile" activeClassName="active">
          <PersonIcon />
          <span>Meus Dados</span>
        </NavLink>

        <span className="sub-title">Anúncios</span>
        <NavLink to="/lodgings" activeClassName="active">
          <HotelIcon />
          <span>Hospedagem</span>
        </NavLink>
        <NavLink to="/businesses" activeClassName="active">
          <BusinessIcon />
          <span>Negócios</span>
        </NavLink>
        <NavLink to="/experiences" activeClassName="active">
          <ExperienceIcon />
          <span>Experiências</span>
        </NavLink>
        <NavLink to="/medicals" activeClassName="active">
          <MedicalIcon />
          <span>Saúde</span>
        </NavLink>
        <NavLink to="/others" activeClassName="active">
          <OthersIcon />
          <span>Diversos</span>
        </NavLink>
        <a href="https://app.micasa420.com/home">
          <ExploreIcon />
          <span>Área do Viajante</span>
        </a>
        <NavLink
          to="/logout"
          activeClassName="active"
          onClick={this.handleLogout}
        >
          <LogoutIcon />
          <span>Logout</span>
        </NavLink>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(null, mapDispatchToProps)(Sidebar);
