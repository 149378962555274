import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { ConnectedRouter } from "connected-react-router";

import store from "./store";

import history from "./routes/history";
import Routes from "./routes";

import GlobalStyle from "./styles/global";

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <GlobalStyle />
      <Routes />
      <ReduxToastr
        closeOnToastrClick
        timeOut={3000}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        position="bottom-right"
      />
    </ConnectedRouter>
  </Provider>
);
export default App;
