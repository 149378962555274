import React, { Component } from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBJumbotron,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBIcon
} from "mdbreact";
import { Link } from "react-router-dom";

import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";
import CheckoutForm from "../../components/CheckoutForm";

import { Container, Wrapper, Content } from "../../styles/pages";

class SubscriptionPage extends Component {
  render() {
    const { id } = this.props.match.params;

    return (
      <Container>
        <TopBar />
        <SideBar />
        <Wrapper>
          <Content>
            <MDBBreadcrumb>
              <MDBBreadcrumbItem>
                <Link to="/dashboard" className="breadcrumb-link">
                  Dashboard
                </Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>Anúncio Premium</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBJumbotron>
              <MDBCardBody>
                <MDBCardTitle className="h2">
                  Anúncio Premium <MDBIcon far icon="gem" />
                </MDBCardTitle>
                <MDBCardText>
                  <p className="centralized-text">
                    Fique em evidência nos nossos mecanismos de busca, adicione
                    imagens ao seu anúncio e informações de contato para que
                    interessados possam enviar e-mails e mensagens via WhatsApp.
                  </p>

                  <p className="centralized-text">
                    Apenas <span className="price">R$ 16,20</span> por mês
                  </p>
                </MDBCardText>
              </MDBCardBody>
            </MDBJumbotron>
            <CheckoutForm id={id}></CheckoutForm>
          </Content>
        </Wrapper>
      </Container>
    );
  }
}

export default SubscriptionPage;
