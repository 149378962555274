import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const Panel = styled.div`
  margin: 1px 0;
  width: 100%;
  background: #fff;
  border-top: none;
  display: flex;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 838px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    padding: 5px;
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  div.div-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Section = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(63, 81, 181, 0.2),
    0px 4px 5px 0px rgba(63, 81, 181, 0.14),
    0px 1px 10px 0px rgba(63, 81, 181, 0.12);

  background: rgb(63, 81, 181);
  color: #fff;
  font-size: 1.2em;
  font-weight: 800;
  letter-spacing: 0.15em;

  span {
    width: 80%;
  }

  svg {
    font-size: 1.5em;
  }

  @media (max-width: 769px) {
    margin-left: 0;
  }
`;
