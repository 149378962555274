import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  getAdRequest: ["id"],
  getAdSuccess: ["data", "medias", "user"],
  getAdComplete: [],
  setAdRequest: ["data"],
  setAdSuccess: ["data"],
  setAdComplete: [],
  setAdFailure: [],
  createAdRequest: ["adType"],
  createAdSuccess: ["id"],
  resetAd: []
});

const INITIAL_STATE = {
  data: null,
  medias: null,
  user: null,
  loading: false,
  id: "",
  error: false
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  data: action.data,
  medias: action.medias,
  user: action.user
});

const createSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  id: action.id
});

const updateSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  data: null
});

const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: true
});

const endLoading = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
});

const reset = (state = INITIAL_STATE) => ({
  ...state,
  data: null
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AD_REQUEST]: request,
  [Types.GET_AD_SUCCESS]: success,
  [Types.GET_AD_COMPLETE]: endLoading,
  [Types.SET_AD_REQUEST]: request,
  [Types.SET_AD_SUCCESS]: updateSuccess,
  [Types.SET_AD_COMPLETE]: endLoading,
  [Types.SET_AD_FAILURE]: failure,
  [Types.CREATE_AD_REQUEST]: request,
  [Types.CREATE_AD_SUCCESS]: createSuccess,
  [Types.RESET_AD]: reset
});

export const AdTypes = Types;

export const AdActions = Creators;
