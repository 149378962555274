import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px 40px 50px;
  width: 100%;

  @media (max-width: 1374px) {
    padding: 10px;
  }
`;

export const ReviewCard = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 0.1em solid #f5f5f5;
  padding: 15px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 700px) {
    padding: 10px 0;
  }

  div.avatar {
    width: 170px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgb(30, 59, 25);

    @media (max-width: 700px) {
      width: 100px;
      padding: 0 10px;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    span.traveler-name {
      text-align: center;
      color: #4a4a4a;

      font-size: 0.9em;
    }
  }

  div.content {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 700px) {
      margin-left: 10px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  font-size: 16px;

  @media (max-width: 700px) {
    font-size: 14px;
  }

  div.title-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    div.rating-posted-date {
      display: flex;
      justify-content: space-between;

      div.rating {
        font-size: 1.5em;
        margin-top: -5px;

        .react-rater-star.is-active {
          color: rgb(212, 175, 55);
        }
        .react-rater-star.is-active-half:before {
          color: rgb(212, 175, 55);
        }

        @media (max-width: 700px) {
          font-size: 1.2em;
        }
      }

      div.posted {
        font-size: 0.8em;

        @media (max-width: 700px) {
          font-size: 0.65em;
        }
      }
    }

    span.title {
      font-size: 1em;
      font-weight: 800;
      margin-top: 5px;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;

  font-size: 0.88em;

  @media (max-width: 700px) {
    font-size: 0.78em;
  }
`;

export const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  width: 100%;
  max-height: 250px;

  span.reply-title {
    font-size: 0.9em;
    font-weight: 800;
    margin-top: 5px;
    color: rgb(61, 81, 112);
  }

  p {
    display: flex;
    flex-wrap: wrap;

    font-size: 0.88em;

    @media (max-width: 700px) {
      font-size: 0.78em;
    }
  }

  div.reply-button {
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: 700px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const NoReviewAlert = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
`;
