import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthActions } from "../../store/ducks/auth";

import {
  PersonIcon,
  LogoutIcon,
  ArrowDownIcon,
  ExploreIcon
} from "../../utils/materialiconslib";

import NavMobile from "../NavMobile";

import { Header, Logo, User, ToggleButton, NavWeb } from "./styles";

import * as IMAGES from "../../assets/images";

class TopBar extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      showMenuMobile: false,
      userName: localStorage.getItem("@Micasa420:userName")
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

    this.showMenuMobile = this.showMenuMobile.bind(this);
    this.closeMenuMobile = this.closeMenuMobile.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  }

  showMenuMobile(event) {
    event.preventDefault();

    this.setState({ showMenuMobile: true }, () => {
      document.addEventListener("click", this.closeMenuMobile);
    });
  }

  closeMenuMobile() {
    this.setState({ showMenuMobile: false }, () => {
      document.removeEventListener("click", this.closeMenuMobile);
    });
  }

  handleLogout = e => {
    e.preventDefault();

    const { signOut } = this.props;

    signOut();
  };

  render() {
    return (
      <Fragment>
        <Header>
          <Logo>
            <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
          </Logo>

          <User>
            <div className="wrapper">
              <span>{this.state.userName}</span>
              <div className="menu">
                <div
                  className={
                    this.state.showMenu ? "btn-user active" : "btn-user"
                  }
                  onClick={this.showMenu}
                >
                  <ArrowDownIcon />
                </div>
                {this.state.showMenu ? (
                  <NavWeb>
                    <NavLink to="/profile" activeClassName="active">
                      <PersonIcon />
                      <span>Meus Dados</span>
                    </NavLink>
                    <a href="https://app.micasa420.com/home">
                      <ExploreIcon />
                      <span>Área do Viajante</span>
                    </a>
                    <NavLink
                      to="/logout"
                      activeClassName="active"
                      onClick={this.handleLogout}
                    >
                      <LogoutIcon />
                      <span>Logout</span>
                    </NavLink>
                  </NavWeb>
                ) : null}
              </div>
            </div>
          </User>
          <ToggleButton
            active={this.state.showMenuMobile}
            onClick={this.showMenuMobile}
          >
            <FontAwesomeIcon icon="bars" />
          </ToggleButton>
        </Header>
        {this.state.showMenuMobile ? <NavMobile /> : null}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(null, mapDispatchToProps)(TopBar);
