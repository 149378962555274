import { call, put } from "redux-saga/effects";
import { actions as toastrActions } from "react-redux-toastr";
import api from "../../services/api";

import { UploadMediaActions } from "../ducks/upload-media";

export function* doUploadMedia(action) {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

  if (!acceptedImageTypes.includes(action.data.type)) {
    yield put(
      toastrActions.add({
        type: "error",
        message: "A imagem deve estar em formato gif, jpeg ou png"
      })
    );
    return yield put(UploadMediaActions.uploadMediaFailure());
  }

  const formData = new FormData();
  const maxSize = 2 * 1024 * 1024;

  if (action.data.size > maxSize) {
    yield put(
      toastrActions.add({
        type: "error",
        message: "O tamanho máximo permitido para este arquivo é de 2mb"
      })
    );
    return yield put(UploadMediaActions.uploadMediaFailure());
  }

  formData.append("media", action.data);

  const response = yield call(api.post, `uploadMedia/${action.ad}`, formData);

  if (response.data) {
    yield put(UploadMediaActions.uploadMediaSuccess(response.data.medias));
  } else {
    yield put(UploadMediaActions.uploadMediaFailure());
  }

  return true;
}

export function* deleteMedia({ id }) {
  const response = yield call(api.delete, `deleteMedia/${id}`);

  if (response.data) {
    return yield put(
      UploadMediaActions.deleteMediaSuccess(response.data.medias)
    );
  } else {
    return yield put(UploadMediaActions.deleteMediaFailure());
  }
}
