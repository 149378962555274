import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { UploadMediaActions } from "../../../store/ducks/upload-media";

import Loading from "../../Loading";
import InputFile from "../../InputFile";

import { Container } from "./styles";

class MediaList extends Component {
  componentDidMount() {
    this.props.resetUploadMedia();
  }

  handleInputImg() {
    const inputFile = document.querySelector(".upload-img");
    inputFile.click();
  }

  uploadMedia = e => {
    e.preventDefault();

    const file = document.querySelector("#input-upload-media").files[0];

    const { uploadMediaRequest, id } = this.props;

    uploadMediaRequest(file, id);
  };

  handleDeleteMedia = id => {
    const { deleteMediaRequest } = this.props;

    deleteMediaRequest(id);
  };

  render() {
    const { medias, uploadedMedia, loadingMedia } = this.props;

    return (
      <Container>
        {loadingMedia ? (
          <Loading />
        ) : (
          <GridList cellHeight={260}>
            <GridListTile key="new-img" cols={0.5} className="img-list">
              <InputFile
                id="input-upload-media"
                className="upload-img"
                type="file"
                accept="image/*"
                onChange={this.uploadMedia}
                style={{ display: "none" }}
              />
              <div className="img-placeholder">
                <div className="icon" onClick={this.handleInputImg}>
                  <FontAwesomeIcon icon="plus" />
                  <span>Adicionar Imagem</span>
                </div>
              </div>
            </GridListTile>
            {uploadedMedia
              ? uploadedMedia.map(tile => (
                  <GridListTile key={tile._id} cols={0.5} className="img-list">
                    <img src={tile.url} alt={tile.title} />
                    <GridListTileBar
                      className="img-tile-bar"
                      actionIcon={
                        <IconButton
                          aria-label={"Excluir"}
                          title="Excluir"
                          onClick={() => this.handleDeleteMedia(tile._id)}
                        >
                          <DeleteIcon style={{ color: "#d54527" }} />
                        </IconButton>
                      }
                    />
                  </GridListTile>
                ))
              : medias
              ? medias.map(tile => (
                  <GridListTile key={tile._id} cols={0.5} className="img-list">
                    <img src={tile.url} alt={tile.title} />
                    <GridListTileBar
                      className="img-tile-bar"
                      actionIcon={
                        <IconButton
                          aria-label={"Excluir"}
                          title="Excluir"
                          onClick={() => this.handleDeleteMedia(tile._id)}
                        >
                          <DeleteIcon style={{ color: "#d54527" }} />
                        </IconButton>
                      }
                    />
                  </GridListTile>
                ))
              : ""}
          </GridList>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  uploadedMedia: state.uploadMedia.data,
  loadingMedia: state.uploadMedia.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UploadMediaActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaList);
