import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login";

import { MDBBtn, MDBSpinner, MDBIcon } from "mdbreact";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthActions } from "../../../store/ducks/auth";

import { Container, SignForm, Logo, Wrapper, FormGroup } from "../styles";

import * as IMAGES from "../../../assets/images";

class SignIn extends Component {
  static propTypes = {
    signInRequest: PropTypes.func.isRequired
  };

  state = {
    loading: false,
    email: "",
    password: ""
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true });
    const { email, password } = this.state;
    const { signInRequest } = this.props;
    signInRequest(email, password);
    this.setState({ loading: false });
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  responseFacebook = response => {
    const { facebookLoginRequest } = this.props;

    facebookLoginRequest(response);
  };

  render() {
    const { email, password } = this.state;

    return (
      <Container>
        <Wrapper>
          <Logo href="https://micasa420.com">
            <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
          </Logo>
          <div className="form-wrapper">
            <SignForm onSubmit={this.handleSubmit}>
              <h1>Login - Anunciantes</h1>

              <FormGroup>
                <label htmlFor="email">
                  E-mail
                  <input
                    type="email"
                    placeholder="Digite seu e-mail"
                    id="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                </label>
              </FormGroup>

              <FormGroup>
                <label htmlFor="password">
                  Senha
                  <input
                    type="password"
                    placeholder="Digite sua senha"
                    id="password"
                    name="password"
                    value={password}
                    onChange={this.handleInputChange}
                  />
                </label>
              </FormGroup>

              <MDBBtn
                type="submit"
                color="teal darken-4"
                disabled={this.state.loading}
              >
                {this.state.loading ? <MDBSpinner /> : "Login"}
              </MDBBtn>
              <FacebookLogin
                appId="2791563624210663"
                fields="name,email,picture"
                callback={this.responseFacebook}
                textButton="Entrar com Facebook"
                cssClass="button-fb-login"
                state="stfblogin"
                icon={<MDBIcon fab icon="facebook-square" size="lg" />}
              />
              <Link to="/forgotpass">Esqueceu a senha?</Link>
              <Link to="/signup">
                <span>Não tem cadastro ainda?</span> Crie sua conta
                gratuitamente
              </Link>
              <span className="label-terms">
                Ao acessar esse site, você estará de acordo com os &nbsp;
                <Link to="/terms_and_conditions" target="_blank">
                  Termos de Uso
                </Link>{" "}
                e &nbsp;
                <Link to="/privacy_policies" target="_blank">
                  Política de Privacidade
                </Link>
              </span>
            </SignForm>
          </div>
        </Wrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(null, mapDispatchToProps)(SignIn);
