import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  DashboardIcon,
  PersonIcon,
  LogoutIcon,
  HotelIcon,
  BusinessIcon,
  ExperienceIcon,
  MedicalIcon,
  OthersIcon
} from "../../utils/materialiconslib";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthActions } from "../../store/ducks/auth";

import { Container } from "./styles";

class NavMobile extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired
  };

  handleLogout = e => {
    e.preventDefault();

    const { signOut } = this.props;

    signOut();
  };

  render() {
    return (
      <Container>
        <div className="nav">
          <NavLink to="/profile" activeClassName="active">
            <PersonIcon />
            <span>Meus Dados</span>
          </NavLink>
          <NavLink to="/dashboard" activeClassName="active">
            <DashboardIcon />
            <span>Dashboard</span>
          </NavLink>
          <NavLink to="/lodgings" activeClassName="active">
            <HotelIcon />
            <span>Hospedagem</span>
          </NavLink>
          <NavLink to="/businesses" activeClassName="active">
            <BusinessIcon />
            <span>Negócios</span>
          </NavLink>
          <NavLink to="/experiences" activeClassName="active">
            <ExperienceIcon />
            <span>Experiências</span>
          </NavLink>
          <NavLink to="/medicals" activeClassName="active">
            <MedicalIcon />
            <span>Saúde</span>
          </NavLink>
          <NavLink to="/others" activeClassName="active">
            <OthersIcon />
            <span>Diversos</span>
          </NavLink>
          <NavLink to="/signout" activeClassName="active">
            <LogoutIcon />
            <span>Logout</span>
          </NavLink>
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(null, mapDispatchToProps)(NavMobile);
