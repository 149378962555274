import React from "react";

import { Container, Title, Description } from "./styles";

const PageNotFound = () => (
  <Container>
    <Title>404 - Página não encontrada</Title>
    <Description>Esta Página está indisponível no momento.</Description>
  </Container>
);

export default PageNotFound;
