import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  display: flex;

  margin-bottom: 5px;

  .md-form {
    width: 80%;
    margin-top: 15px;
    margin-bottom: 0;

    @media (max-width: 770px) {
      width: 100%;
    }
  }

  div.button-location {
    margin-left: 15px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
