import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1600px) {
    flex-direction: column;
  }

  a {
    width: 18%;
    text-decoration: none;

    @media (max-width: 1600px) {
      padding: 10px;
      width: 40%;
    }

    @media (max-width: 960px) {
      padding: 10px;
      width: 60%;
    }

    @media (max-width: 720px) {
      padding: 10px;
      width: 100%;
    }
  }
`;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;
  background-color: ${props =>
    props.hotel
      ? "rgb(95, 80, 183)"
      : props.business
      ? "rgb(118, 189, 247)"
      : props.medical
      ? "rgb(255,178,193)"
      : props.others
      ? "rgb(240,169,33)"
      : "rgb(115, 193, 29)"};
  color: rgb(255, 255, 255);
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 30px;
`;

export const CardText = styled.div`
  width: 100%;
  padding: 20px 15px 20px 20px;
  display: flex;
  flex-direction: column;

  span.number {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1;
    margin: 0 0 5px;
  }

  span.text {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    line-height: 1.2;
  }
`;
