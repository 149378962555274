import React from "react";

import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";
import Dashboard from "../../components/Dashboard";

import {
  Container,
  Wrapper,
  Content,
  Title,
  SubTitle
} from "../../styles/pages";

const Main = () => (
  <Container>
    <TopBar />
    <SideBar />
    <Wrapper>
      <Content>
        <Title>Dashboard</Title>

        <SubTitle>Anúncios ativos</SubTitle>
        <Dashboard />
      </Content>
    </Wrapper>
  </Container>
);

export default Main;
