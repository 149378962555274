import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  setSubscriptionRequest: ["data"],
  setSubscriptionSuccess: [],
  setSubscriptionFailure: [],
  setCancelSubscriptionRequest: ["id"],
  setCancelSubscriptionSuccess: [],
  setCancelSubscriptionFailure: []
});

const INITIAL_STATE = {
  data: null,
  loading: false
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const success = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
});

const failure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SUBSCRIPTION_REQUEST]: request,
  [Types.SET_SUBSCRIPTION_SUCCESS]: success,
  [Types.SET_SUBSCRIPTION_FAILURE]: failure,
  [Types.SET_CANCEL_SUBSCRIPTION_REQUEST]: request,
  [Types.SET_CANCEL_SUBSCRIPTION_SUCCESS]: success,
  [Types.SET_CANCEL_SUBSCRIPTION_FAILURE]: failure
});

export const SubscriptionTypes = Types;

export const SubscriptionActions = Creators;
