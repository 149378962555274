import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { AuthActions } from "../../store/ducks/auth";

const PrivateRoute = props => {
  const { component: Component, ...rest } = props;

  const route = p =>
    props.signedIn ? <Component {...p} /> : <Redirect to={{ pathname: "/" }} />;

  return <Route {...rest} render={p => route(p)} />;
};

PrivateRoute.propTypes = {
  signedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  signedIn: state.auth.signedIn
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
