import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrActions } from "react-redux-toastr";
import api from "../../services/api";
import handleError from "./error-handler";

import { ProfileActions } from "../ducks/profile";

export function* getProfile() {
  try {
    const response = yield call(api.get, "/profile");
    if (response.data) {
      yield put(ProfileActions.getProfileSuccess(response.data.user));
    } else {
      localStorage.removeItem("@Micasa420:token");

      yield put(push("/"));
    }

    yield put(ProfileActions.getProfileComplete());
  } catch (error) {
    localStorage.removeItem("@Micasa420:token");

    yield put(push("/"));
  }
}

export function* setProfile(action) {
  const response = yield call(api.put, "/users", action.data);
  if (response.data) {
    yield put(
      toastrActions.add({
        type: "success",
        message: "Dados atualizados."
      })
    );

    localStorage.setItem(
      "@Micasa420:userName",
      `${response.data.user.first_name} ${response.data.user.last_name}`
    );

    yield put(ProfileActions.setProfileSuccess(response.data));
    yield put(push("/dashboard"));
  } else {
    yield put(handleError(response));
  }

  yield put(ProfileActions.setProfileComplete());
}

export function* deleteProfile() {
  localStorage.removeItem("@Micasa420:token");
  localStorage.removeItem("@Micasa420:userName");

  const response = yield call(api.delete, "/user");

  if (response.data.ok) {
    yield put(push("/account_deleted"));
  } else {
    yield put(handleError(response));
  }
}
