import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  uploadAdRequest: ["data", "ad"],
  uploadAdSuccess: ["data"],
  uploadAdFailure: [],
  resetUpload: [],
});

const INITIAL_STATE = {
  data: null,
  loading: false,
  loaded: false
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  loaded: true,
  data: action.data
});

const failure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  data: null
});

const reset = (state = INITIAL_STATE) => ({
  ...state,
  data: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPLOAD_AD_REQUEST]: request,
  [Types.UPLOAD_AD_SUCCESS]: success,
  [Types.UPLOAD_AD_FAILURE]: failure,
  [Types.RESET_UPLOAD]: reset,
});

export const UploadAdTypes = Types;

export const UploadAdActions = Creators;
