import React from "react";

import { MDBContainer } from "mdbreact";

import TopBarStatic from "../../components/TopBarStatic";

import { Container } from "../../styles/pages";

import IMG_HEADER from "../../assets/images/header_home.png";

export default function Home(props) {
  return (
    <Container>
      <TopBarStatic />
      <div style={{ marginTop: "55px" }}>
        <img src={IMG_HEADER} alt="" />
      </div>

      <MDBContainer style={{ marginTop: "25px" }}>
        <h1>TERMOS DE USO - Micasa420</h1>
        <p>
          LEIA ESTES TERMOS DE USO COM ATENÇÃO ANTES DE UTILIZAR OS SERVIÇOS
          OFERECIDOS PELO MICASA PUBLICIDADE WEB LTDA. ESTES TERMOS DE USO
          ESTABELECEM OS TERMOS E CONDIÇÕES JURIDICAMENTE VINCULATIVOS PARA O
          USO DO SITE EM WWW.MICASA420.COM, ASSIM COMO DO NOSSO APLICATIVO
          MÓVEL, E OS SERVIÇOS , RECURSOS, CONTEÚDO, APLICATIVOS OU WIDGETS
          OFERECIDOS PELO MICASA420 (COLETIVAMENTE COM OS SITES E OS
          APLICATIVOS, OS &quot;SERVIÇOS&quot;).
        </p>
        <h2>Aceitação dos termos.</h2>
        <p>
          Ao se registrar e/ou usar os Serviços de toda e qualquer forma,
          incluindo, mas não se limitando a, visitar ou utilizar o Site, onde
          aplicável, você concorda com todos os termos e condições contidos aqui
          (&quot;Termos de Uso&quot;), que também incorporam a Política de
          Privacidade do Micasa420, Política de Propriedade Intelectual do
          Micasa420, Diretrizes de Fotografia do Micasa420, Termos e Condições
          do Estabelecimento do Micasa420, políticas e procedimentos que podem
          ser publicados de tempos em tempos no Site pelo Micasa420, do qual
          cada um é incorporado por referência e pode ser atualizado pelo
          Micasa420 de tempos em tempos, sem avisos, de acordo com os termos
          estabelecidos na seção &quot;Modificação dos Termos de Uso&quot;
          abaixo. Além disso, alguns serviços oferecidos pelo Serviços podem
          estar sujeitos a termos e condições adicionais especificados pelo
          Micasa420 de tempos em tempos; o uso de tais serviços está sujeito aos
          termos e condições adicionais, que são incorporadas nestes Termos de
          Uso por meio desta referência. Estes Termos de Uso se aplicam a todos
          os usuários dos Serviços, incluindo, mas não se limitando a, usuários
          contribuidores de conteúdo, informação e outros materiais ou serviços
          no(s) Site(s), usuários individuais dos Serviços, estabelecimentos que
          acessam os Serviços e usuários que possuem uma página nos Serviços.
        </p>
        <h2>Acesso.</h2>
        <p>
          De acordo com estes Termos de Uso, o Micasa420 pode oferecer os
          Serviços, descritos com mais exatidão nos nossos Sites, e que são
          selecionados por você somente para seu uso pessoal e não para o uso ou
          benefício de qualquer terceiro. Os serviços devem incluir, mas não se
          limitar a, quaisquer serviços realizados para você pelo Micasa420,
          quaisquer aplicativos ou widgets oferecidos pelo Micasa420 baixados
          dos nossos Sites ou, sujeito aos termos estabelecidos na seção
          &quot;Sites e Serviços de terceiros&quot; abaixo, de lojas de
          aplicativos de terceiros (p. ex., iTunes® store ou Google Apps
          Marketplace) autorizados pelo Micasa420, assim como o oferecimento de
          quaisquer materiais mostrados ou realizados em ou pelos Serviços
          (incluindo Conteúdo conforme definido abaixo).
        </p>
        <h2>Cadastro e elegibilidade.</h2>
        <p>
          Você poderá precisar cadastrar uma conta para acessar alguns ou todos
          os nossos Serviços. Se cadastrar uma conta no Micasa420, você deverá
          fornecer ao Micasa420 informações de cadastro exatas e completas
          (incluindo, mas não limitado a, seu endereço de e-mail e/ou número de
          telefone celular – opcional - e uma senha que será usada para acessar
          o Serviço). Você também manterá suas informações de cadastro exatas e
          atualizadas. Deixar de fazer isso constituirá uma violação dos Termos
          de Uso, que pode resultar na rescisão imediata da sua conta no
          Micasa420.{" "}
        </p>

        <p>Você concorda em não:</p>
        <p>
          * criar uma conta para ninguém além de você mesmo sem a permissão de
          tal pessoa;
        </p>
        <p>
          * criar um nome de usuário que seja o nome de outra pessoa com a
          intenção de se passar por essa pessoa;
        </p>
        <p>
          * criar um nome de usuário ou conta do Micasa420 que esteja sujeita a
          quaisquer direitos de uma pessoa que não seja você, sem a devida
          autorização; ou
        </p>
        <p>
          * criar um nome de usuário que seja um nome que ofensivo, vulgar,
          obsceno ou ilegal.
        </p>
        <p>
          {" "}
          O Micasa420 reserva-se o direito de recusar o cadastro de, ou cancelar
          um nome de usuário, a seu exclusivo critério. Você é o único
          responsável por atividades que ocorrem em sua conta e será responsável
          por manter a confidencialidade de sua senha do Micasa420. Você nunca
          deverá usar a conta de outro usuário sem sua autorização prévia. Você
          deverá notificar imediatamente por escrito o Micasa420 sobre qualquer
          uso não autorizado de sua conta, ou qualquer outra violação de
          segurança relacionada a uma conta de que você está ciente.{" "}
        </p>
        <p>
          Você deve ter pelo menos 18 anos de idade (ou a idade da maioridade
          legal onde reside) para acessar os Serviços. O Serviço não está
          disponível para indivíduos com menos de 18 anos de idade. O Micasa420
          pode, a seu exclusivo critério, recusar-se a oferecer os Serviços a
          qualquer pessoa ou entidade e alterar seus critérios de elegibilidade
          a qualquer momento.
        </p>
        <p>
          Se você usar um aparelho móvel, esteja ciente de que as tarifas e
          taxas habituais de sua operadora, tais como cobranças de mensagens e
          dados, ainda serão aplicadas. Em caso de mudança ou desativação do
          número do seu telefone celular, você concorda que atualizará as
          informações de sua conta nos Serviços dentro de 48 horas para garantir
          que suas mensagens não sejam enviadas para a pessoa que adquirir o seu
          número anterior.
        </p>
        <h2>Privacidade</h2>
        <p>
          Ao usar os Serviços, você concorda com a coleta, uso e divulgação de
          informações do Micasa420 sobre você e seu dispositivo. Consulte a
          nossa Política de Privacidade para obter informações sobre como
          coletamos, usamos e divulgamos informações sobre você e seu aparelho
          móvel. Leia as nossas práticas de coleta de dados com atenção antes de
          usar os nossos Serviços.
        </p>
        <h2>Conteúdo.</h2>
        <p>
          Todo o Conteúdo, seja ele veiculado publicamente ou transmitido
          privativamente, é de exclusiva responsabilidade da pessoa que o
          originou. O Micasa420 não pode garantir a autenticidade de quaisquer
          Conteúdo ou dados que usuários fornecem sobre si mesmos. Você
          reconhece que todo o Conteúdo que acessar usando o Serviço será por
          sua conta e risco e você será o único responsável por qualquer dano ou
          perda decorrente para você ou qualquer outra parte. Para fins destes
          Termos de Uso, o termo &quot;Conteúdo&quot; inclui, sem limitação,
          qualquer informação sobre a localização, vídeos, clipes de áudio,
          comentários, informações, dados, textos, fotografias, software,
          scripts, gráficos e recursos interativos gerados, fornecidos ou
          tornados acessíveis por você para o Micasa420 nos ou pelos anunciantes
          de Serviços. Conteúdo adicionado, criado, carregado, enviado,
          distribuído, publicado ou obtido por você por meio dos Serviços,
          incluindo Conteúdo que você adiciona aos Serviços caso vincule suas
          contas a sites e serviços de terceiros são coletivamente referidos
          como &quot;Envios de Usuário&quot;.
        </p>
        <h2>Conteúdo Micasa420.</h2>
        <p>
          Os Serviços contém Conteúdo especificamente fornecido pelo Micasa420
          ou seus parceiros e licenciadores, e tal Conteúdo é protegido por
          direitos autorais, marcas registradas, marcas de serviço, patentes,
          segredos industriais ou outros direitos e leis de propriedade
          aplicáveis. Você deve respeitar e manter todos os avisos, informações
          e restrições de direitos autorais contidos em qualquer Conteúdo
          acessado por meio dos Serviços.
        </p>
        <p>
          Sujeito a estes Termos de Uso, o Micasa420 concede a você uma licença
          pessoal, mundial, isenta de royalties, não exclusiva, não
          sublicenciável, intransferível e revogável para acessar e usar o
          Conteúdo exclusivamente para uso não comercial. Você não pode copiar,
          modificar, distribuir, vender ou arrendar qualquer parte dos nossos
          Serviços, nem fazer engenharia reversa ou tentar extrair o
          código-fonte do software, a menos que as leis proíbam essas restrições
          ou você tenha nossa permissão por escrito para fazê-lo. Você concorda
          em não autorizar ou permitir que terceiros distribuam ou
          disponibilizem os Apps por meio de uma rede, onde possam ser usados
          por vários dispositivos ao mesmo tempo; e você também não removerá,
          alterará ou ocultará qualquer direito autoral, marca registrada ou
          outra notificação de direitos de propriedade sobre ou nos Serviços.
          Você não poderá vender, licenciar, alugar, utilizar ou explorar
          qualquer Conteúdo para fins comerciais (com ou sem fins lucrativos) ou
          de forma que viole qualquer direito de terceiros.
        </p>
        <h2>Envios de Usuário.</h2>
        <p>
          Nós poderemos usar seus Envios de Usuário de diversas maneiras
          relacionadas com os nosso Site, Serviços e os negócios do Micasa420,
          da maneira como o Micasa420 determinar a seu exclusivo critério,
          incluindo, sem limitação, exibi-los publicamente, reformatando-os,
          incorporando-os em materiais de marketing, anúncios e outras obras,
          criando obras derivadas dos envios, promovendo-os, distribuindo-os e
          permitindo que outros usuários façam o mesmo em relação a seus
          próprios sites, plataformas de mídia e aplicativos (\&quot;Mídia de
          terceiros\&quot;). Ao enviar Envios de Usuário no nosso Sites ou pelos
          Serviços, você concede ao Micasa420 uma licença sublicenciável e
          transferível perpétua, irrevogável, mundial, não exclusiva, livre de
          royalties e totalmente paga para hospedar, usar, armazenar, copiar,
          editar, modificar, reproduzir, distribuir, preparar obras derivadas,
          exibir, realizar e explorar totalmente os Envios de Usuário e qualquer
          conteúdo que enviar ao Micasa420 para fins comerciais (p. ex.,
          promover o Sorteio do qual participou) relacionados ao Site, Serviços
          e aos negócios do Micasa420 (e seus sucessores e representantes),
          incluindo, sem limitação, a promoção e redistribuição de parte ou todo
          do Site (e suas obras derivadas) ou Serviços em qualquer formato de
          mídia e por meio de qualquer canal de mídia (incluindo, sem limitação,
          sites e feeds de terceiros). Você também concede a cada usuário dos
          nossos Sites e/ou dos Serviços, incluindo Mídia de terceiros, uma
          licença não exclusiva para acessar seus Envios de Usuário por meio dos
          nossos Sites e Serviços; e para usar, editar, modificar, reproduzir,
          distribuir e preparar obras derivadas, exibir e executar tais Envios
          de Usuário em relação ao uso dos nossos Sites, Serviços e Mídia de
          terceiros. Para fins de esclarecimento, a concessão de licença
          supracitada ao Micasa420 não afeta seus outros direitos de propriedade
          ou licença em seu(s) Envio(s) de Usuário, incluindo o direito de
          conceder licenças adicionais ao material em seu(s) Envio(s) de
          Usuário, salvo acordado por escrito com o Micasa420.
        </p>
        <p>
          Você representa e garante ter todos os direitos para nos conceder essa
          licença sem infringir nem violar direitos de terceiros, incluindo, sem
          limitação, quaisquer direitos de privacidade, direitos de publicidade,
          direitos autorais, diretos contratuais ou qualquer outro direito
          proprietário ou de propriedade intelectual.
        </p>
        <p>
          Você entende que toda informação postada publicamente ou transmitida
          em privacidade pelo Site é de única responsabilidade da pessoa que
          originou o Conteúdo; que o Micasa420 não será responsabilizado por
          quaisquer erros ou omissões de Conteúdo; e que o Micasa420 não poderá
          garantir a identidade de quaisquer outros usuários com quem você possa
          interagir durante seu uso dos Serviços.
        </p>
        <h2>Regras e conduta.</h2>
        <p>
          Como condição do seu uso, você não usará os Serviços para qualquer
          finalidade que seja proibida por estes Termos de Uso. Você é o único
          responsável por todas as suas atividades em conexão com os Serviços.
          Além disso, você deve respeitar todas as leis e regulamentos locais,
          estaduais, nacionais e internacionais e, se você representa uma
          empresa, qualquer norma de publicidade, marketing, privacidade ou de
          auto-regulação de sua indústria.
        </p>
        <p>
          Você não deve (e não deve permitir o mesmo a terceiros) (a) tomar
          qualquer atitude ou (b) fazer upload, download, publicar, enviar,
          distribuir ou facilitar a distribuição de qualquer Conteúdo no, ou por
          meio dos Serviços, incluindo, sem limitação, qualquer Envio de
          Usuário, que:
        </p>
        <p>
          * viole qualquer patente, marca registrada, segredo industrial,
          direito autoral, direito de publicidade ou outro direito de qualquer
          pessoa ou entidade ou que viole qualquer lei ou obrigação contratual;
        </p>
        <p>* você reconheça como falso, enganoso, mentiroso ou inexato;</p>
        <p>
          * seja ilegal, ameaçador, abusivo, ofensivo, difamatório, predatório,
          persecutório, calunioso, enganoso, fraudulento, invada a privacidade
          do outro, injurioso, obsceno, vulgar, pornográfico, ofensivo, profano,
          contenha ou retrate a nudez, contenha ou retrate a atividade sexual,
          promova intolerância, discriminação ou violência, ou seja inadequado
          ou questionável, conforme determinado pelo Micasa420 a seu exclusivo
          critério. O Micasa420 tem uma política de tolerância zero contra a
          pornografia infantil, e excluirá e denunciará às autoridades
          apropriadas qualquer usuário que publique ou distribua pornografia
          infantil.
        </p>
        <p>
          * constitui publicidade não autorizada ou não solicitada, e-mail lixo
          ou em massa (&quot;spam&quot;);
        </p>
        <p>
          * envolva atividades comerciais (com ou sem fins lucrativos) e/ou
          vendas sem consentimento prévio por escrito do Micasa420, como
          concursos, sorteios, permutas, publicidade ou esquemas de pirâmide;
        </p>
        <p>
          * contenha vírus de software ou qualquer outro código, arquivos,
          worms, bombas lógicas ou programas de computador que sejam concebidos
          ou destinados a interromper, inutilizar, danificar, limitar ou
          interferir no bom funcionamento de qualquer software, hardware ou
          equipamento de telecomunicações ou para danificar ou obter acesso não
          autorizado a qualquer sistema, dados, senhas ou outras informações do
          Micasa420 ou de terceiros;
        </p>
        <p>
          * imite qualquer pessoa ou entidade, incluindo qualquer funcionário ou
          representante do Micasa420;
        </p>
        <p>
          * inclua documentos de identificação ou informações confidenciais de
          uma pessoa; ou
        </p>
        <p>
          * viole a <a href="/privacy_policies">Política de Privacidade</a>
           e/ou qualquer uma das outras políticas e normas aqui incorporadas.
        </p>
        <p>Você não deve (direta ou indiretamente):</p>
        <p>
          * realizar uma ação que imponha ou possa impor (conforme determinado
          pelo Micasa420 de acordo com seu exclusivo critério) uma carga
          irracional ou desproporcionalmente grande para a infraestrutura do
          Micasa420 (ou de seus provedores terceirizados);
        </p>
        <p>
          * interferir ou tentar interferir no funcionamento adequado do Serviço
          ou nas atividades realizadas no Serviço;
        </p>
        <p>
          * ignorar quaisquer medidas que possam ser usadas pelo Micasa420 para
          evitar ou restringir acesso ao Serviço (ou a outras contas, sistemas
          de computador ou redes conectadas ao Serviço);
        </p>
        <p>
          * executar qualquer resposta automática ou &quot;spam&quot; no
          Serviço;
        </p>
        <p>
          * usar softwares, dispositivos ou outros processos manuais ou
          automáticos para realizar atos de &quot;crawl&quot; ou
          &quot;spider&quot; em qualquer página no Site;
        </p>
        <p>* coletar ou extrair qualquer conteúdo do Serviço;</p>
        <p>
          * modificar, adaptar, apropriar, reproduzir, distribuir, traduzir,
          criar obras derivadas ou adaptações, exibir publicamente, republicar,
          reapropriar, vender, comercializar ou de alguma forma explorar o
          Serviço ou Conteúdo do Site, como expressamente autorizado pelo
          Micasa420;
        </p>
        <p>
          * decifrar, descompilar, desmontar, fazer engenharia reversa ou tentar
          obter qualquer código- fonte ou ideias ou algoritmos subjacentes de
          qualquer parte do Serviço (incluindo, sem limitação, qualquer
          aplicativo ou widget), exceto na medida limitada em que leis proíbem
          especificamente tal restrição;
        </p>
        <p>
          * copiar, alugar, arrendar, distribuir ou transferir quaisquer
          direitos que você recebe aqui;
        </p>
        <p>
          * usar os Serviços de uma maneira que possa interferir, interromper,
          afetar negativamente ou impedir que outros usuários aproveitem
          plenamente os nossos serviços, ou que possa danificar, inutilizar,
          sobrecarregar ou prejudicar o funcionamento dos nossos serviços de
          alguma maneira;
        </p>
        <p>
          * realizar uma ação que viole as diretrizes e políticas do Micasa420.
        </p>
        <p>
          * adicionar qualquer imagem ou texto que contenham informações
          privadas das pessoas, como números do cartão de crédito ou do
          telefone, ou fotos de pessoas que não querem que sua foto seja
          publicada.
        </p>
        <p>
          O Micasa420 não garante que qualquer Conteúdo ou Envios de Usuário
          (conforme definido acima) serão disponibilizados no Site ou por meio
          do Serviço. O Micasa420 não tem nenhuma obrigação de monitorar os
          Sites, Serviços, Conteúdo ou Envios de Usuário. Entretanto, o
          Micasa420 se reserva o direito de (i) remover, suspender, editar ou
          modificar qualquer Conteúdo a seu exclusivo critério, incluindo, sem
          limitação, qualquer Envio de Usuário a qualquer momento, sem aviso a
          você e por qualquer motivo (incluindo, mas não limitado a, recebimento
          de reivindicações ou alegações de terceiros ou autoridades em relação
          a tal Conteúdo ou se o Micasa420 considerar que você tenha violado
          estes Termos de Uso), ou sem qualquer justificativa, e (ii) remover,
          suspender ou bloquear qualquer Envio de Usuário do Serviço. O
          Micasa420 também se reserva o direito de acessar, ler, preservar e
          divulgar qualquer informação conforme o Micasa420 acredite ser
          razoavelmente necessário para (i) satisfazer qualquer lei,
          regulamentação, processo legal ou solicitação governamental aplicável,
          (ii) fazer cumprir estes Termos de Uso, incluindo a investigação de
          possíveis violações dele, (iii) detectar, impedir ou de outra forma
          discutir fraude, segurança ou questões técnicas, (iv) responder a
          pedidos de suporte do usuário ou (v) proteger os direitos, a
          propriedade ou a segurança do Micasa420, seus usuários e o público.
        </p>
        <h2>Serviços de cartão</h2>
        <p>
          Determinados recursos dos nossos Serviços e/ou determinados serviços e
          produtos disponíveis para você por meio dos Serviços podem permitir a
          compra de serviços (\&quot;Serviços de cartão\&quot;). O Micasa420 usa
          um processador de pagamento terceirizado (o \&quot;Pagar.me\&quot;)
          para vincular sua conta do cartão de crédito aos Serviços (sua
          \&quot;Conta de cartão\&quot;) para o uso dos Serviços de cartão. O
          processamento de pagamentos ou créditos, conforme aplicável, em
          conexão com os Serviços de cartão, estará sujeito aos termos,
          condições e políticas de privacidade do Processador de pagamentos e da
          bandeira do seu cartão de crédito, além do presente Acordo. O
          Micasa420 não é responsável por quaisquer erros do Processador de
          pagamentos. Em relação ao seu uso dos Serviços de cartão, o Micasa420
          obterá determinados detalhes da transação, que o Micasa420 usará
          exclusivamente de acordo com a nossa 
          <a href="/privacy_policies">Política de Privacidade</a>.
        </p>
        <h2>Termos especiais para páginas.</h2>
        <p>
          Se você tiver um anúncio no Serviço, você concorda com o seguinte:
        </p>
        <p>
          * Os anúncios só podem ser usados para promover prestação de serviços
          por pessoas físicas, empresas, ou outra organização ou iniciativa
          comercial, política ou beneficente (incluindo organizações sem fins
          lucrativos, campanhas políticas, bandas e celebridades).
        </p>
        <p>
          * Você só pode administrar um anúncio publicado por você ou se for um
          representante autorizado pelo autor do anúncio.
        </p>
        <p>
          * Todo conteúdo postado nos anúncios será disponibilizado para todos
          os usuários dos Serviços.
        </p>
        <p>
          * Você não pode estabelecer outros termos além dos aqui definidos
          nestes Termos de Uso para controlar o uso do anúncio que você
          administra
        </p>
        <p>
          * Você não vai direcionar seu anúncio, ou qualquer conteúdo do mesmo,
          para pessoas com idade inferior a 18 anos.
        </p>
        <p>
          Se você coletar informações sobre usuários, você irá usar essas
          informações somente para fins comerciais internos em conexão com o seu
          uso do Serviço e de acordo com a Política de Privacidade do Micasa420.
        </p>
        <p>
          Você não irá usar práticas enganosas para que os usuários
          &quot;curtam&quot; seu anúncio.
        </p>
        <p>
          Você é responsável por garantir que o seu anúncio, incluindo qualquer
          conteúdo que você postar no mesmo, cumpra todas as leis, regras e
          regulamentos e estes Termos de Uso e qualquer tipo de publicidade,
          marketing, privacidade ou outros códigos de auto-regulação,
          aplicável(eis) ​ao seu setor.
        </p>
        <h2>Propagandas e promoções.</h2>
        <p>
          Nosso App pode veicular anúncios e promoções de terceiros em - ou sem
          - conexão com os Serviços, ou pode fornecer informações ou links para
          produtos ou serviços de terceiros em - ou sem - conexão com os
          Serviços. Suas transações comerciais, ou correspondência ou
          participação em promoções de tais terceiros, e quaisquer termos,
          condições, garantias ou representações associadas a tais transações ou
          promoções, são exclusivamente entre você e tal terceiro. O Micasa420
          não é responsável por qualquer perda ou dano de qualquer tipo
          incorrido como resultado de tais transações ou promoções ou como
          resultado da presença de tais anunciantes não Micasa420 ou informações
          de terceiros nos Serviços.
        </p>
        <h2>Sites e serviços de terceiros.</h2>
        <p>
          O Serviço pode permitir que você faça links para outros sites,
          serviços ou recursos na internet, e outros sites, serviços ou recursos
          podem conter links para o Site. Ao acessar sites de terceiros, você o
          faz por sua conta e risco. Os termos de cada parte regerão o
          relacionamento da respectiva parte com você. Esses outros sites não
          estão sob controle do Micasa420, e você reconhece que o Micasa420 não
          se responsabiliza pelo conteúdo, funções, exatidão, legalidade,
          conveniência ou qualquer outro aspecto de tais sites ou recursos. A
          inclusão de qualquer link não implica o endosso do Micasa420 ou
          qualquer associação com seus operadores. Você também reconhece e
          concorda que o Micasa420 não será responsável, direta ou
          indiretamente, por qualquer perda ou dano causado ou supostamente
          causado por ou em conexão com o uso de ou confiança em tais conteúdos,
          bens ou serviços disponíveis em ou por meio de tal site ou recurso.
        </p>
        <h2>Terminação.</h2>
        <p>
          O Micasa420 pode excluir seu acesso a todo ou qualquer parte do
          Serviço e/ou link do anúncio a qualquer momento, com ou sem causa, com
          ou sem aviso, com efeito imediato, que pode resultar em confisco e
          destruição de toda informação associada com os Serviços. Se você
          deseja excluir sua conta, basta seguir as instruções contidas no nosso
          Site. Quaisquer honorários pagos não serão reembolsáveis. Todas as
          disposições destes Termos de Uso devem, por sua natureza, sobreviver à
          rescisão, incluindo, sem limitação, disposições de propriedade,
          isenção de garantia, indenização e limitações de responsabilidade.
        </p>
        <h2>Isenção de garantia.</h2>
        <p>
          O Micasa420 não tem nenhuma relação especial ou obrigação fiduciária
          com você. Você reconhece que o Micasa420 não tem nenhum controle e
          nenhuma obrigação de tomar medidas a respeito de: quais usuários obtêm
          acesso aos Serviços; qual Conteúdo você acessa pelos Serviços; os
          efeitos, se houver, que o Conteúdo exercem sobre você; como você
          interpreta ou usa o Conteúdo; ou quais medidas você toma como
          resultado da exposição ao Conteúdo.
        </p>
        <p>
          Você isenta o Micasa420 de toda a responsabilidade de você ter
          adquirido ou não Conteúdo pelos Serviços. Os Serviços podem conter, ou
          direcioná-lo para sites que contenham, informações que algumas pessoas
          podem achar inadequadas ou impróprias. O Micasa420 não será
          responsável pela exatidão, cumprimento de direitos autorais,
          legalidade ou decência do material contido ou acessado por meio do
          Serviço.
        </p>
        <p>
          Você nos isenta de qualquer responsabilidade em relação às suas
          conexões e relacionamentos com outros usuários dos Sites. Você entende
          que, de nenhuma maneira, nós fazemos triagem de usuários, nem
          investigamos os antecedentes dos usuários e tampouco tentamos
          verificar seus antecedentes ou declarações. Nós não fazemos
          representações ou garantias quanto ao comportamento dos usuários ou à
          veracidade de qualquer informação fornecida pelos usuários. De nenhuma
          forma seremos responsáveis por quaisquer danos, sejam diretos,
          indiretos, gerais, especiais, compensatórios, consequentes e/ou
          acidentais, resultantes ou relacionados com a sua conduta ou de
          qualquer outra pessoa em conexão com os Serviços, incluindo, sem
          limitação, lesão corporal, sofrimento emocional e quaisquer danos
          resultantes de comunicações ou reuniões com usuários ou pessoas que
          você possa vir a conhecer por meio dos Serviços. Como tal, você
          concorda em tomar precauções razoáveis e exercer o máximo de cuidado
          em todas as interações com qualquer pessoa que entrar em contato por
          meio dos Serviços, principalmente se você decidir encontrar essas
          pessoas pessoalmente. Por exemplo, você não deve, em nenhuma
          circunstância, fornecer seus dados financeiros às pessoas (p. ex.,
          números do cartão de crédito ou conta bancária).
        </p>
        <p>
          O SITE, SERVIÇO E CONTEÚDO É PROVIDENCIADO &quot;NO ESTADO EM QUE SE
          ENCONTRA&quot;, &quot;DE ACORDO COM A DISPONIBILIDADE&quot; E SEM
          GARANTIA DE QUALQUER TIPO, EXPRESSA OU IMPLÍCITA, INCLUINDO, MAS NÃO
          LIMITADO A, GARANTIAS IMPLÍCITAS DE TÍTULO, NÃO VIOLAÇÃO,
          POSSIBILIDADE DE COMERCIALIZAÇÃO E ADEQUAÇÃO A UMA DETERMINADA
          FINALIDADE, E QUALQUER GARANTIA IMPLÍCITA POR QUALQUER RESULTADO DE
          DESEMPENHO OU UTILIZAÇÃO COMERCIAL, DAS QUAIS SÃO EXPRESSAMENTE
          RENUNCIADAS, SALVO NA MEDIDA DA LEI.
        </p>
        <p>
          O MICASA420 E SEUS DIRETORES, FUNCIONÁRIOS, AGENTES, REPRESENTANTES,
          FORNECEDORES, PARCEIROS E PROVEDORES DE CONTEÚDO NÃO GARANTEM QUE: (A)
          OS SERVIÇOS SEJAM SEGUROS, LIVRES DE ERROS, ININTERRUPTOS OU
          DISPONÍVEIS A QUALQUER MOMENTO OU LOCAL DETERMINADOS; (B) QUALQUER
          DEFEITO OU ERRO SERÁ CORRIGIDO; (C); QUALQUER CONTEÚDO OU SOFTWARE
          DISPONÍVEL NO OU PELOS SERVIÇOS SEJA LIVRE DE VÍRUS OU OUTROS
          COMPONENTES PREJUDICIAIS; OU (D) OS RESULTADOS DO USO DOS SERVIÇOS IRÁ
          SATISFAZER SUAS EXIGÊNCIAS. O USO DO SITE, SERVIÇOS, CONTEÚDO E LINK
          &#39;SALVAR&#39; É POR SUA PRÓPRIA CONTA E RISCO. ALGUNS
          ESTADOS/PAÍSES NÃO PERMITEM LIMITAÇÕES SOBRE GARANTIAS IMPLÍCITAS.
          PORTANTO, AS LIMITAÇÕES ACIMA TALVEZ NÃO SE APLIQUEM A VOCÊ.
        </p>
        <h2>Indenização.</h2>
        <p>
          Você deverá defender, indenizar e isentar o Micasa420, suas
          subsidiárias, afiliados e todos os funcionários, contratantes,
          diretores, fornecedores e representantes das suas subsidiárias e
          afiliados, por todas as perdas, custos, ações, reclamações, danos,
          despesas (incluindo custos legais cabíveis) ou responsabilidades que
          surgirem a partir de ou relacionadas ao seu uso ou uso indevido, ou
          acesso ao, Site, Serviços, Conteúdo, ou de outra forma de seus Envios
          de Usuário, violação destes Termos de Uso, ou violação por você ou
          quaisquer terceiros usando sua conta, de qualquer propriedade
          intelectual ou outro direito de qualquer pessoa ou entidade. O
          Micasa420 reserva-se o direito de assumir a defesa exclusiva e o
          controle de qualquer assunto sujeito a indenização por você, no qual
          você ajudará e cooperará com o Micasa420 a apoiar qualquer defesa
          disponível.
        </p>
        <h2>Limitação de responsabilidade.</h2>
        <p>
          TODA RESPONSABILIDADE DO MICASA420, SUAS SUBSIDIÁRIAS E AFILIADOS,
          CADA UM DELES E SEUS DIRETORES, FUNCIONÁRIOS, AGENTES, REPRESENTANTES,
          PARCEIROS, FORNECEDORES OU PROVEDORES DE CONTEÚDO QUE DERIVEM DE
          QUALQUER PERDA SOFRIDA COMO RESULTADO DO SEU USO DOS NOSSOS SITES,
          SERVIÇOS, CONTEÚDO, ENVIOS DE USUÁRIO, É EXPRESSAMENTE EXCLUÍDA ATÉ O
          MÁXIMO PERMITIDO POR LEI.
        </p>
        <p>
          DENTRO DO ALCANCE MÁXIMO PERMITIDO PELAS LEIS APLICÁVEIS, EM NENHUMA
          CIRCUNSTÂNCIA O MICASA420, SUAS SUBSIDIÁRIAS E AFILIADOS, NEM ELES E
          SEUS DIRETORES, FUNCIONÁRIOS, AGENTES, REPRESENTANTES, PARCEIROS,
          FORNECEDORES OU PROVEDORES DE CONTEÚDO SERÃO RESPONSABILIZADOS POR
          CONTRATO, RESPONSABILIDADE CIVIL, RESPONSABILIDADE OBJETIVA,
          NEGLIGÊNCIA OU QUALQUER OUTRA TEORIA JURÍDICA OU EQUITATIVA OU POR
          OUTRA FORMA (QUER O MICASA420, SEUS DIRETORES, FUNCIONÁRIOS, AGENTES,
          REPRESENTANTES, PARCEIROS, FORNECEDORES OU PROVEDORES DE CONTEÚDO
          TENHAM OU NÃO TIDO CONHECIMENTO PRÉVIO DAS CIRCUNSTÂNCIAS QUE DERAM
          ORIGEM A TAL PERDA OU DANO) RELATIVA AO NOSSO SITE, SERVIÇOS, CONTEÚDO
          OU ENVIOS DE USUÁRIO:
        </p>
        <p>* PERDAS OU DANOS INDIRETOS OU CONSEQUENTES;</p>
        <p>* PERDA DE LUCROS REAIS OU ANTECIPADOS;</p>
        <p>* PERDA DE RECEITA;</p>
        <p>* PERDA DE BOA FÉ;</p>
        <p>* PERDA DE DADOS;</p>
        <p>* PERDA DE ECONOMIAS ANTECIPADAS;</p>
        <p>* DESPESAS DESPERDIÇADAS; OU</p>
        <p>* CUSTO DE AQUISIÇÃO DE BENS E SERVIÇOS SUBSTITUTOS.</p>
        <p>
          NADA NESTES TERMOS DE USO DEVE SER CONSIDERADO COMO EXCLUSÃO OU
          LIMITAÇÃO DA SUA RESPONSABILIDADE EM RELAÇÃO A QUALQUER INDENIZAÇÃO
          FEITA POR VOCÊ DE ACORDO COM ESTES TERMOS DE USO. LEIS APLICÁVEIS
          PODEM NÃO PERMITIR A LIMITAÇÃO OU EXCLUSÃO DE RESPONSABILIDADE OU
          DANOS INCIDENTAIS OU CONSEQUENTES; PORTANTO, A LIMITAÇÃO OU EXCLUSÃO
          ACIMA PODE NÃO SER APLICÁVEL A VOCÊ. NESSES CASOS, A RESPONSABILIDADE
          DO MICASA420 SERÁ LIMITADA AO MÁXIMO PERMITIDO POR LEI APLICÁVEL.
        </p>
        <h2>Lei aplicável.</h2>
        <p>
          Uma versão impressa destes Termos de Uso e de qualquer aviso feito em
          formato eletrônico serão admissíveis nos processos jurídicos ou
          administrativos, baseados em ou relacionados a estes Termos de Uso na
          mesma medida, e sujeitos às mesmas condições de outros documentos e
          registros comerciais originalmente gerados e mantidos em formato
          impresso. Você e o Micasa420 concordam que qualquer causa de ação
          decorrente ou relacionada ao Serviço deve começar dentro de um (1) ano
          após a causa de ação. Caso contrário, tal causa será impedida
          permanentemente.
        </p>
        <h2>Integração e separação.</h2>
        <p>
          Estes Termos de Uso constituem no acordo integral entre você e o
          Micasa420, referente ao Serviço e uso do Site, Serviço, Conteúdo e
          Envios de usuário, e substitui todas as comunicações e propostas
          anteriores ou contemporâneas (sejam verbais, escritas ou eletrônicas)
          entre você e o Micasa420 em relação aos Sites. Se qualquer disposição
          destes Termos de Uso for considerada inexequível ou inválida, tal
          disposição será limitada ou excluída na medida mínima necessária para
          que estes Termos de Uso permaneçam em pleno vigor, efeito e
          exequíveis. A incapacidade de qualquer uma das partes em exercer em
          qualquer aspecto de qualquer direito previsto aqui não deverá ser
          considerada uma renúncia de quaisquer direitos a seguir. A renúncia de
          conformidade em qualquer caso particular não significa que iremos
          renunciar a conformidade no futuro. Para que qualquer renúncia de
          conformidade com estes Termos de Uso seja obrigatória, o Micasa420
          deve fornecer um aviso por escrito de tal renúncia por meio de um dos
          seus representantes autorizados.
        </p>
        <h2>Modificação dos Termos de Uso.</h2>
        <p>
          O Micasa420 se reserva o direito, a seu exclusivo critério, de
          modificar ou substituir qualquer um destes Termos de Uso, ou alterar,
          suspender ou descontinuar os Serviços (incluindo, sem limitação, a
          disponibilidade de qualquer recurso, banco de dados ou conteúdo) a
          qualquer momento ao publicar um aviso no Site ou enviar um aviso por
          meio dos Serviços ou por e-mail. O Micasa420 também pode impor limites
          a certos recursos e serviços ou restringir seu acesso a partes ou à
          totalidade dos Serviços, sem aviso ou responsabilidade. É de sua
          responsabilidade verificar estes Termos de Uso periodicamente para
          observar mudanças. Seu uso contínuo dos Serviços após a publicação de
          quaisquer alterações a estes Termos de Uso constitui aceitação destas
          alterações.
        </p>
        <h2>Diversos.</h2>
        <p>
          O Micasa420 não será responsável por qualquer descumprimento das suas
          obrigações que seja resultado de qualquer causa além do controle
          razoável do Micasa420, incluindo, sem limitação, falha ou degradação
          mecânica, eletrônica ou de comunicação (incluindo interferência
          &quot;ruído de linha&quot;). Estes Termos de Uso são pessoais e não
          são transmissíveis, transferíveis ou sublicenciáveis por você, exceto
          com o consentimento prévio por escrito do Micasa420. O Micasa420
          poderá atribuir, transferir ou delegar qualquer dos seus direitos e
          obrigações aqui previstos sem seu consentimento. Nenhuma agência,
          parceria, joint venture ou relação empregatícia é criada como
          resultado destes Termos de Uso e nenhuma das partes tem autoridade
          para vincular a outra sob nenhum aspecto.
        </p>
        <p>
          Salvo especificado o contrário nestes Termos de Uso, todas as
          notificações sob estes Termos de Uso serão por escrito e consideradas
          como devidamente entregues quando forem recebidas, se entregues
          pessoalmente ou enviadas por carta registrada, com aviso de
          recebimento; quando o recebimento for confirmado eletronicamente, se
          transmitida por fax ou e-mail; ou um dia depois de ser enviada, se for
          enviada para entrega no dia seguinte por um reconhecido serviço de
          entrega para chegar no dia seguinte.
        </p>
        <p>
          Exclusivamente em relação aos usuários localizados no EEE, com
          referência a estes Termos de Uso e a sua utilização dos nossos Sites,
          Serviços, Conteúdo ou Envios de Usuário, nada nestes Termos de Uso
          será considerado como garantia de qualquer direito ou benefício a
          nenhuma pessoa que não seja você e nós (e nossos respectivos
          sucessores de título ou designados), nem dá direito a nenhum terceiro
          de executar qualquer disposição deste, e você e nós concordamos que
          não pretendemos tornar nenhuma disposição destes Termos de Uso
          executáveis por um terceiro em virtude do Contracts (Rights of Third
          Parties) Act 1999 (a Lei de contratos (Direitos de terceiros) 1999).
        </p>
        <h2>Idioma.</h2>
        <p>
          Onde o Micasa420 lhe forneceu uma tradução da versão em português
          destes Termos de Uso, você concorda que esta tradução serve apenas
          para sua conveniência e que as versões em língua portuguesa dos Termos
          irão reger seu relacionamento com o Micasa420.
        </p>
        <p>
          Se houver qualquer contradição entre a versão em português destes
          Termos de Uso e a sua tradução, a versão em português prevalecerá.
        </p>
        <h2>Contato.</h2>
        <p>
          Você pode entrar em contato com o Micasa420 pelo e-mail:
          app.micasa420@gmail.com ou no endereço: Alameda dos Vinháticos, nº270,
          Sala 2, Mosteiro de Itaici, Indaiatuba-SP- Brasil. CEP. 13341-040.
        </p>
      </MDBContainer>
    </Container>
  );
}
