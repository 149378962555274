import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "../components/PrivateRoute";
import PageNotFound from "../components/PageNotFound";

import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import ForgotPass from "../pages/Auth/ForgotPass";
import ResetPass from "../pages/Auth/ResetPass";

import Main from "../pages/Main";

import Profile from "../pages/Profile";

import LodgingList from "../pages/Ads/Lodging/List";
import LodgingEdit from "../pages/Ads/Lodging/Edit";

import BusinessList from "../pages/Ads/Business/List";
import BusinessEdit from "../pages/Ads/Business/Edit";

import ExperienceList from "../pages/Ads/Experience/List";
import ExperienceEdit from "../pages/Ads/Experience/Edit";

import MedicalList from "../pages/Ads/Medical/List";
import MedicalEdit from "../pages/Ads/Medical/Edit";

import OthersList from "../pages/Ads/Others/List";
import OthersEdit from "../pages/Ads/Others/Edit";

import Subscription from "../pages/Subscription";

import TermsOfUse from "../pages/TermsOfUse";
import PrivacyPolicies from "../pages/PrivacyPolicies";
import CookiePolicies from "../pages/CookiePolicies";
import AccountDeleted from "../pages/AccountDeleted";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={SignIn} />
    <Route exact path="/signup" component={SignUp} />

    <Route exact path="/terms_and_conditions" component={TermsOfUse} />
    <Route exact path="/privacy_policies" component={PrivacyPolicies} />
    <Route exact path="/cookie_policies" component={CookiePolicies} />
    <Route exact path="/account_deleted" component={AccountDeleted} />

    <Route exact path="/forgotpass" component={ForgotPass} />
    <Route exact path="/resetpass/:token" component={ResetPass} />

    <PrivateRoute exact path="/dashboard" component={Main} />
    <PrivateRoute exact path="/profile" component={Profile} />

    <PrivateRoute exact path="/lodgings" component={LodgingList} />
    <PrivateRoute
      exact
      path={`/lodging/:id/:premium`}
      component={LodgingEdit}
    />

    <PrivateRoute exact path="/businesses" component={BusinessList} />
    <PrivateRoute
      exact
      path={`/business/:id/:premium`}
      component={BusinessEdit}
    />

    <PrivateRoute exact path="/experiences" component={ExperienceList} />
    <PrivateRoute
      exact
      path={`/experience/:id/:premium`}
      component={ExperienceEdit}
    />

    <PrivateRoute exact path="/medicals" component={MedicalList} />
    <PrivateRoute
      exact
      path={`/medical/:id/:premium`}
      component={MedicalEdit}
    />

    <PrivateRoute exact path="/others" component={OthersList} />
    <PrivateRoute exact path={`/other/:id/:premium`} component={OthersEdit} />

    <PrivateRoute exact path={`/subscription/:id`} component={Subscription} />

    <Route path="*" exact component={PageNotFound} />
  </Switch>
);

export default Routes;
