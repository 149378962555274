import React, { Component } from "react";
import GoogleMapLoader from "react-google-maps-loader";
import Autocomplete from "react-google-autocomplete";
import ReactGoogleMap from "react-google-map";
import { MDBBtn, MDBIcon, MDBInput } from "mdbreact";
import { Header } from "./styles";

class AutocompleteGoogle extends Component {
  state = {
    editing: true,
    address: "",
    mapPosition: {
      lat: -23.5372547,
      lng: -46.5754858
    },
    markerPosition: {
      lat: -23.5372547,
      lng: -46.5754858
    }
  };

  componentDidMount() {
    if (this.props.address) {
      this.setCustomPosition(
        this.props.address,
        this.props.lat,
        this.props.lng
      );
    } else {
      this.getLocation();
    }
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setDefaultPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  setDefaultPosition = position => {
    this.setState({
      markerPosition: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      },
      mapPosition: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    });
  };

  setCustomPosition = (address, lat, lng) => {
    this.setState({
      editing: false,
      address: address,
      markerPosition: {
        lat,
        lng
      },
      mapPosition: {
        lat,
        lng
      }
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.editing !== nextState.editing ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCity = addressArray => {
    const result = addressArray.filter(
      obj => obj.types[0] && obj.types[0] === "administrative_area_level_2"
    );
    return result[0] ? result[0].long_name : "";
  };

  getState = addressArray => {
    const result = addressArray.filter(
      obj => obj.types[0] && obj.types[0] === "administrative_area_level_1"
    );
    return result[0] ? result[0].long_name : "";
  };

  getCountry = addressArray => {
    const result = addressArray.filter(
      obj => obj.types[0] && obj.types[0] === "country"
    );
    return result[0] ? result[0].long_name : "";
  };

  onPlaceSelected = place => {
    const addressArray = place.address_components;

    const newLocation = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address: place.formatted_address,
      city: this.getCity(addressArray) || this.getState(addressArray),
      state: this.getState(addressArray),
      country: this.getCountry(addressArray)
    };

    this.setState({
      editing: false,
      address: newLocation.address,
      markerPosition: {
        lat: newLocation.latitude,
        lng: newLocation.longitude
      },
      mapPosition: {
        lat: newLocation.latitude,
        lng: newLocation.longitude
      }
    });

    this.props.onPlaceChange(newLocation);
  };

  handleEditing = e => {
    e.preventDefault();

    this.setState({
      editing: true
    });
  };

  render() {
    return (
      <GoogleMapLoader
        params={{
          key: process.env.REACT_APP_GOOGLE_API_KEY,
          libraries: "places,geocode,geometry",
          language: "pt-BR"
        }}
        render={googleMaps =>
          googleMaps && (
            <div>
              {this.state.editing ? (
                <div
                  id="header"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "5px"
                  }}
                >
                  <Autocomplete
                    id="input-places"
                    style={{
                      width: "100%",
                      height: "40px",
                      paddingLeft: "16px",
                      marginBottom: "5px"
                    }}
                    placeholder="Informe a localização"
                    onPlaceSelected={this.onPlaceSelected}
                    types={["geocode"]}
                    language={"pt-BR"}
                  />
                </div>
              ) : (
                <Header>
                  <MDBInput
                    label="Localização atual"
                    value={this.state.address}
                    disabled
                    size="sm"
                  ></MDBInput>
                  <div className="button-location">
                    <MDBBtn
                      onClick={this.handleEditing}
                      outline
                      color="primary"
                      size="sm"
                    >
                      Editar
                      <MDBIcon icon="edit" className="ml-2" />
                    </MDBBtn>
                  </div>
                </Header>
              )}
              <div id="map-coordinates" style={{ height: "300px" }}>
                <ReactGoogleMap
                  googleMaps={googleMaps}
                  center={{
                    lat: this.state.mapPosition.lat,
                    lng: this.state.mapPosition.lng
                  }}
                  coordinates={[
                    {
                      position: {
                        lat: this.state.mapPosition.lat,
                        lng: this.state.mapPosition.lng
                      },
                      onLoaded: (googleMaps, map, marker) => {
                        map.setCenter({
                          lat: this.state.mapPosition.lat,
                          lng: this.state.mapPosition.lng
                        });
                      }
                    }
                  ]}
                  zoom={16}
                  onLoaded={(googleMaps, map) => {
                    map.setMapTypeId(googleMaps.MapTypeId.ROADMAP);
                  }}
                />
              </div>
            </div>
          )
        }
      />
    );
  }
}

export default AutocompleteGoogle;
