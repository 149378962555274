import { call, put } from "redux-saga/effects";
import pagarme from "pagarme";
import api from "../../services/api";
import { toastr } from "react-redux-toastr";

import { SubscriptionActions } from "../ducks/subscription";

export function* setSubscription(action) {
  const { ad, document_number, number, name, expiry, cvc } = action.data;

  const card_hash = yield pagarme.client
    .connect({ encryption_key: process.env.REACT_APP_PAGARME_ENCRYPT_KEY })
    .then(client => {
      return client.security.encrypt({
        card_number: number,
        card_holder_name: name,
        card_expiration_date: expiry,
        card_cvv: cvc
      });
    });

  const response = yield call(api.post, "/subscription", {
    ad,
    card_hash,
    document_number
  });

  if (response.data.ok) {
    yield put(SubscriptionActions.setSubscriptionSuccess());
  } else {
    yield put(SubscriptionActions.setSubscriptionFailure());
  }
}

export function* cancelSubscription({ id }) {
  const response = yield call(api.put, `/cancelSubscription/${id}`);

  if (response.data.ok) {
    yield put(SubscriptionActions.setCancelSubscriptionSuccess());
    toastr.success(
      "",
      "Sua solicitação de cancelamento foi enviada com sucesso"
    );
  } else {
    yield put(SubscriptionActions.setCancelSubscriptionFailure());
  }
}
