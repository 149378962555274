import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { MDBBtn, MDBSpinner } from "mdbreact";

import {
  Container,
  SignForm,
  FormGroup,
  Logo,
  Wrapper,
  ErrorBox
} from "../styles";

import * as IMAGES from "../../../assets/images";

class ResetPass extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string.isRequired
      })
    })
  };

  state = {
    loading: false,
    email: "",
    password: "",
    password_confirmation: "",
    updated: false,
    isLoading: true,
    error: "",
    nullError: false
  };

  async componentDidMount() {
    const token = this.props.match.params.token;

    await axios
      .get(`${process.env.REACT_APP_API_URL}/reset/${token}`)
      .then(response => {
        this.setState({
          email: response.data.email,
          updated: false,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          updated: false,
          isLoading: false,
          error: "invalid token"
        });
      });
  }

  handleRedirectLogin = () => {
    this.props.history.push("/");
  };

  handleRedirectNewToken = () => {
    this.props.history.push("/forgotpass");
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true });
    const { email, password, password_confirmation } = this.state;

    if (password === "") {
      this.setState({
        nullError: true,
        loading: false
      });
    } else if (password !== password_confirmation) {
      this.setState({
        updated: false,
        error: "confirmation failed",
        loading: false
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/updatePassword`, {
          email,
          password
        })
        .then(() => {
          this.setState({
            updated: true,
            nullError: false,
            loading: false
          });
        })
        .catch(() => {
          this.setState({
            updated: false,
            isLoading: false,
            loading: false,
            error: "update failed"
          });
        });
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value, nullError: false });
  };

  render() {
    const {
      password,
      password_confirmation,
      error,
      isLoading,
      updated,
      nullError
    } = this.state;

    if (isLoading) {
      return (
        <Container>
          <Wrapper>
            <Logo href="https://micasa420.com">
              <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
            </Logo>
            <SignForm>
              <MDBSpinner />
            </SignForm>
          </Wrapper>
        </Container>
      );
    }

    if (error === "invalid token") {
      return (
        <Container>
          <Wrapper>
            <Logo href="https://micasa420.com">
              <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
            </Logo>
            <SignForm>
              <h3>
                Seu token para troca de senha expirou, clique abaixo para enviar
                um e-mail com um novo link
              </h3>
              <MDBBtn
                type="button"
                color="teal darken-4"
                disabled={this.state.loading}
                onClick={this.handleRedirectNewToken}
              >
                {this.state.loading ? <MDBSpinner /> : "Enviar"}
              </MDBBtn>
            </SignForm>
          </Wrapper>
        </Container>
      );
    }

    if (updated) {
      return (
        <Container>
          <Wrapper>
            <Logo href="https://micasa420.com">
              <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
            </Logo>
            <SignForm>
              <h1>
                Sua senha foi atualizada com sucesso! Clique abaixo para fazer o
                seu login
              </h1>
              <MDBBtn
                type="button"
                color="teal darken-4"
                disabled={this.state.loading}
                onClick={this.handleRedirectLogin}
              >
                {this.state.loading ? <MDBSpinner /> : "Login"}
              </MDBBtn>
            </SignForm>
          </Wrapper>
        </Container>
      );
    }

    return (
      <Container>
        <Wrapper>
          <Logo href="https://micasa420.com">
            <img src={IMAGES.LOGO} alt="Micasa420 logo" width="400" />
          </Logo>
          <SignForm onSubmit={this.handleSubmit}>
            <h1>Atualize sua senha nos campos abaixo</h1>

            <FormGroup>
              <label htmlFor="password">
                Nova senha
                <input
                  type="password"
                  placeholder="Digite sua nova senha"
                  id="password"
                  name="password"
                  value={password}
                  onChange={this.handleInputChange}
                />
              </label>
            </FormGroup>

            <FormGroup>
              <label htmlFor="password_confirmation">
                Confirmação da senha
                <input
                  type="password"
                  placeholder="Confirme sua nova senha"
                  id="password_confirmation"
                  name="password_confirmation"
                  value={password_confirmation}
                  onChange={this.handleInputChange}
                />
              </label>
            </FormGroup>

            <MDBBtn
              type="submit"
              color="teal darken-4"
              disabled={this.state.loading}
            >
              {this.state.loading ? <MDBSpinner /> : "Salvar"}
            </MDBBtn>

            {nullError ? (
              <ErrorBox>
                <span>Favor informar a senha</span>
              </ErrorBox>
            ) : (
              ""
            )}
            {error === "confirmation failed" ? (
              <ErrorBox>
                <span>
                  A confirmação da senha não coincide com a senha informada
                </span>
              </ErrorBox>
            ) : error === "update failed" ? (
              <ErrorBox>
                <span>
                  Ocorreu um erro ao atualizar a senha, tente novamente
                </span>
              </ErrorBox>
            ) : (
              ""
            )}
          </SignForm>
        </Wrapper>
      </Container>
    );
  }
}

export default ResetPass;
