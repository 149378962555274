import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  div.new-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;

    span {
      margin-left: 15px;
      font-size: 1.1em;
      letter-spacing: 0.125em;
      font-weight: 500;
    }

    @media (max-width: 400px) {
      padding: 5px;

      span {
        margin-left: 5px;
        font-size: 1em;
        letter-spacing: 0.125em;
        font-weight: 500;
      }
    }
  }
`;

export const Panel = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px 15px 10px;
  box-shadow: 0px 8px 15px rgba(75, 72, 72, 0.1);

  p {
    margin: 25px;
  }

  ul {
    width: 100%;
    list-style: none;

    li {
      border-bottom: 1px solid rgba(75, 72, 72, 0.1);
    }

    li:last-child {
      border-bottom: 0;
    }
  }
`;

export const Ad = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 18px;
  height: 215px;

  @media (max-width: 580px) {
    font-size: 16px;
    padding: 5px;
  }

  div.item-data {
    display: flex;

    div.item-pic {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      width: 150px;

      @media (max-width: 580px) {
        width: 130px;
      }

      img {
        width: 100%;
        height: auto;
        max-height: 180px;
        border-radius: 5px;

        @media (max-width: 580px) {
          max-height: 130px;
        }
      }

      a {
        width: 100%;
        display: flex;
        justify-content: center;

        text-decoration: none;
        span {
          font-size: 0.85em;
          text-align: center;
          color: #0000ff;
          font-weight: 700;
        }
      }

      span.posted-date {
        font-size: 0.55em;
        font-weight: 300;
      }

      @media (max-width: 810px) {
        font-size: 0.8em;
      }
    }

    div.wrapper {
      margin-left: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media (max-width: 810px) {
        flex-direction: column;
      }

      div.item-info {
        margin: 10px 0;
        display: flex;
        flex-direction: column;

        a {
          font-size: 1em;
          text-decoration: none;
          font-weight: 800;
          color: rgb(136, 51, 77);
        }

        span.type {
          font-size: 0.66em;
          font-weight: 500;
          color: rgba(136, 51, 77, 0.8);
        }

        .icon {
          margin-left: 10px;
          color: rgb(133, 217, 255);
        }

        @media (max-width: 810px) {
          font-size: 0.88em;
        }
      }

      div.review {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 18px;
        text-align: right;

        @media (max-width: 810px) {
          margin: 0;
          text-align: left;
          font-size: 0.77em;
        }

        div.rating-reviews {
          font-size: 1em;
          margin-top: -5px;
          display: flex;
          flex-direction: column;

          .react-rater-star.is-active {
            color: rgb(212, 175, 55);
          }
          .react-rater-star.is-active-half:before {
            color: rgb(212, 175, 55);
          }

          a.total-reviews {
            font-size: 0.77em;
            text-decoration: none;
            font-weight: 800;
            color: rgb(0, 176, 255);
            margin-bottom: 10px;
          }
        }

        span.status {
          margin: 0 10px 5px 10px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          font-size: 0.77em;
          font-weight: 600;
          color: ${props =>
            props.theme.active ? "rgb(0, 229, 52)" : "rgb(255, 3, 49)"};
          text-shadow: ${props =>
            props.theme.active
              ? "0px 0px 6px rgba(0, 229, 52, 0.7)"
              : "0px 0px 6px rgba(255, 125, 149, 0.7)"};

          @media (max-width: 810px) {
            margin: 0;
          }
        }
      }

      div.actions {
        display: flex;
        flex-direction: row-reverse;

        a:first-child {
          margin-left: 10px;
        }
      }
    }
  }
`;
