import React, { Component } from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import SideBar from "../../../../components/SideBar";
import AdTabs from "../../../../components/Ads/Tabs";

import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";

import { Container, Wrapper, Content } from "../../../../styles/pages";

class BusinessEditPage extends Component {
  render() {
    const { id, premium } = this.props.match.params;

    return (
      <Container>
        <TopBar />
        <SideBar />
        <Wrapper>
          <Content>
            <MDBBreadcrumb>
              <MDBBreadcrumbItem>
                <Link to="/dashboard" className="breadcrumb-link">
                  Dashboard
                </Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem>
                <Link to="/businesses" className="breadcrumb-link">
                  Negócios
                </Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>Edição de Anúncio</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <AdTabs
              adType={"business"}
              id={id}
              premium={premium}
              {...this.props}
            />
          </Content>
        </Wrapper>
      </Container>
    );
  }
}

export default BusinessEditPage;
