import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopBar from "../../../../components/TopBar";
import SideBar from "../../../../components/SideBar";
import AdList from "../../../../components/Ads/List";

import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";

import { Container, Wrapper, Content } from "../../../../styles/pages";

class BusinessListPage extends Component {
  render() {
    return (
      <Container>
        <TopBar />
        <SideBar />
        <Wrapper>
          <Content>
            <MDBBreadcrumb>
              <MDBBreadcrumbItem>
                <Link to="/dashboard" className="breadcrumb-link">
                  Dashboard
                </Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>Saúde</MDBBreadcrumbItem>
            </MDBBreadcrumb>

            <AdList adType={"medical"} />
          </Content>
        </Wrapper>
      </Container>
    );
  }
}

export default BusinessListPage;
