import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 2;
  margin-top: 42px;
  background-color: #fff;
  box-shadow: 0 4px 7px 0 hsla(0, 0%, 63%, 0.71);
  width: 100%;

  @media (min-width: 770px) {
    display: none;
  }

  div.nav {
    width: 100%;

    padding: 0;

    a {
      display: inline-block;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 12px;
      border-left: 3px solid #fff;
      color: #3d5170;
      text-decoration: none;
      align-items: center;

      &:hover {
        border-left: 3px solid #88334d;
        background-color: rgba(136, 51, 77, 0.1);
      }

      svg {
        width: 10%;
        font-size: 20px;
        margin-left: 15px;
        margin-right: 10px;
        align-items: center;
        vertical-align: middle;
        display: inline-block;
      }

      span {
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
  a:last-child {
    border-top: 0.1px solid #f6f6f6;
  }
`;
