import * as Yup from "yup";

const MESSAGE = "Essa informação é obrigatória.";

const validationSchema = Yup.object().shape({
  number: Yup.string().required(MESSAGE),
  name: Yup.string().required(MESSAGE),
  expiry: Yup.string().required(MESSAGE),
  cvc: Yup.string().required(MESSAGE)
});

export default validationSchema;
