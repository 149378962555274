import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { toastr, actions as toastrActions } from "react-redux-toastr";
import api from "../../services/api";
import handleError from "./error-handler";

import { AdActions } from "../ducks/ad";

export function* getAd({ id }) {
  try {
    const response = yield call(api.get, `/ads/${id}`);
    if (response.data) {
      yield put(
        AdActions.getAdSuccess(
          response.data.ad,
          response.data.medias,
          response.data.ad.user
        )
      );
    } else {
      yield put(handleError(response));
    }
    yield put(AdActions.getAdComplete());
  } catch (error) {
    yield put(handleError());
  }
}

export function* createAd({ adType }) {
  const response = yield call(
    api.post,
    `/ads/${adType === "other" ? "others" : adType}`
  );

  if (response.data) {
    yield put(AdActions.createAdSuccess(response.data.id));
    yield put(push(`/${adType}/${response.data.id}/free`));
  } else {
    yield put(handleError(response));
  }
}

export function* setAd(action) {
  if (action.data.activation) {
    if (!action.data.address && !action.data.city) {
      yield put(AdActions.setAdFailure());

      return yield put(
        toastrActions.add({
          type: "error",
          title: "Falha ao salvar",
          message:
            "É necessário informar a localização antes de ativar o anúncio"
        })
      );
    }
    if (!action.data.main_pic) {
      yield put(AdActions.setAdFailure());
      return yield put(
        toastrActions.add({
          type: "error",
          title: "Falha ao salvar",
          message:
            "É necessário adicionar a foto de capa antes de ativar o anúncio"
        })
      );
    }
  }

  let response;

  action.data.active = action.data.activation;

  if (!action.data.id) {
    response = yield call(api.post, "/createAd", action.data);
  } else {
    response = yield call(api.put, `/updateAd/${action.data.id}`, action.data);
  }

  const { type } = action.data;
  const adType =
    type === "lodging"
      ? "lodgings"
      : type === "business"
      ? "businesses"
      : type === "experience"
      ? "experiences"
      : type === "medical"
      ? "medicals"
      : "others";

  if (response.data.ok) {
    toastr.success("", "Anúncio salvo");

    yield put(push(`/${adType}`));
  } else {
    yield put(handleError(response));
  }
}
