import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withFormik } from "formik";
import CurrencyInput from "react-currency-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import {
  MDBTabPane,
  MDBTabContent,
  MDBAlert,
  MDBInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBSwitch,
  MDBCol,
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBSpinner
} from "mdbreact";

import Autocomplete from "../../Autocomplete";
import Loading from "../../Loading";
import InputFile from "../../InputFile";
import MediaList from "../MediaList";
import ReviewList from "../ReviewList";
import Textarea from "../../../styles/components/Textarea";

import { AdActions } from "../../../store/ducks/ad";
import { SubscriptionActions } from "../../../store/ducks/subscription";
import { UploadAdActions } from "../../../store/ducks/upload-ad";

import getSubTypes from "../../../utils/subtypes";
import getCurrencies from "../../../utils/currencies";
import getAccomodationTypes from "../../../utils/accommodationTypes";

import {
  Container,
  Panel,
  Form,
  FormGroup,
  WrapperMap,
  Section,
  Row,
  StatusContainer,
  PremiumSectionAlert
} from "./styles";

import validationSchema from "./validationSchema";

class AdForm extends Component {
  state = {
    accommodationTypes: [],
    subTypes: [],
    currencies: [],
    openDialog: false
  };

  async componentDidMount() {
    this.props.resetUpload();

    await this.props.getAdRequest(this.props.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.uploadedMainPic !== prevProps.uploadedMainPic) {
      const { setFieldValue, uploadedMainPic } = this.props;

      if (uploadedMainPic) {
        setFieldValue("main_pic", uploadedMainPic.url);
      }
    }

    if (this.props.ad !== prevProps.ad) {
      this.setState({
        accommodationTypes: getAccomodationTypes(
          this.props.ad.accommodation_type
        )
      });
      this.setState({
        subTypes: getSubTypes(this.props.adType, this.props.ad.sub_type)
      });
      this.setState({ currencies: getCurrencies(this.props.ad.currency) });
    }

    if (
      this.props.values.activation !== prevProps.values.activation &&
      this.props.values.active === prevProps.values.active
    ) {
      const { setAdRequest, values } = this.props;
      setAdRequest(values);
    }
  }

  componentWillUnmount() {
    this.props.resetAd();
  }

  handleInputMainImg() {
    const inputFile = document.querySelector(".upload-main-img");
    inputFile.click();
  }

  uploadMainPic = e => {
    e.preventDefault();

    const file = document.querySelector("#input-upload-main-pic").files[0];

    const { uploadAdRequest, values } = this.props;

    uploadAdRequest(file, values.id);
  };

  handleChangePrice = (event, maskedvalue, floatvalue) => {
    const { setFieldValue } = this.props;
    setFieldValue("price", floatvalue);
  };

  handleChangeCleaningFee = (event, maskedvalue, floatvalue) => {
    const { setFieldValue } = this.props;
    setFieldValue("cleaning_fee", floatvalue);
  };

  handleLocationChange = location => {
    const { setFieldValue } = this.props;

    setFieldValue("latitude", location ? location.latitude : 0);
    setFieldValue("longitude", location ? location.longitude : 0);
    setFieldValue("address", location ? location.address : "");
    setFieldValue("city", location ? location.city : "");
    setFieldValue("country", location ? location.country : "");
  };

  handleSelectChange = fieldName => selected => {
    const { setFieldValue } = this.props;

    setFieldValue(fieldName, selected[0] ? selected[0] : {});
  };

  handleSwitchChange = fieldName => () => {
    const { setFieldValue, values } = this.props;

    setFieldValue(fieldName, !values[fieldName]);
  };

  handleActivation = () => {
    const { setFieldValue } = this.props;

    setFieldValue("posted_date", new Date());
    setFieldValue("activation", true);
  };

  handleDeactivation = () => {
    const { setFieldValue } = this.props;

    setFieldValue("posted_date", null);
    setFieldValue("activation", false);
  };

  handleSubscription = () => {
    const { ad } = this.props;

    this.props.history.push({
      pathname: `/subscription/${ad._id}`
    });
  };

  handleCancelSubscription = async () => {
    const { ad, setFieldValue, setCancelSubscriptionRequest } = this.props;

    await setCancelSubscriptionRequest(ad._id);

    setFieldValue({ cancelation_requested: true });
    setFieldValue({ premium: false });
  };

  handleDialogOpen = () => {
    this.setState({ openDialog: true });
  };

  handleDialogClose = e => {
    e.preventDefault();
    this.setState({ openDialog: false });
  };

  render() {
    const {
      adType,
      ad,
      medias,
      loadingAd,
      loadingMainPic,
      uploadedMainPic,
      values,
      errors,
      handleSubmit,
      handleChange
    } = this.props;

    const { subTypes, accommodationTypes, currencies } = this.state;

    if (!ad || loadingAd) {
      return (
        <Container>
          <MDBSpinner />
        </Container>
      );
    }

    if (ad) {
      return (
        <Container>
          <Panel>
            <Form autoComplete="new-password">
              <MDBTabContent
                className="card"
                activeItem={this.props.activeItem}
              >
                <MDBTabPane tabId="1" role="tabpanel">
                  <div className="wrapper">
                    <Section>
                      <span>Publicação</span>
                      <MDBIcon icon="ad" size="lg" />
                    </Section>
                    <StatusContainer
                      premium={values.premium}
                      active={values.active}
                    >
                      <div className="status-wrapper">
                        <label htmlFor="active">Status</label>
                        <span className="active">
                          {values.active ? "Ativo" : "Inativo"}
                        </span>
                        {values.active ? (
                          <MDBBtn
                            color="danger"
                            rounded
                            size="sm"
                            onClick={() => this.handleDeactivation()}
                          >
                            <MDBIcon icon="times" className="mr-1" />
                            Desativar Publicação
                          </MDBBtn>
                        ) : (
                          <MDBBtn
                            color="info"
                            rounded
                            size="sm"
                            onClick={() => this.handleActivation()}
                          >
                            <MDBIcon icon="check" className="mr-1" /> Ativar
                            Publicação
                          </MDBBtn>
                        )}
                      </div>
                      {/* <div className="status-wrapper">
                        <label htmlFor="premium">Tipo de Anúncio</label>
                        <span className="premium">
                          {values.cancelation_requested
                            ? "Aguardando cancelamento de assinatura"
                            : values.premium
                            ? "premium"
                            : "free"}
                        </span>
                        {values.cancelation_requested ? (
                          ""
                        ) : values.premium ? (
                          <MDBBtn
                            color="danger"
                            rounded
                            size="sm"
                            onClick={() => this.handleDialogOpen()}
                            disabled={loadingSub}
                          >
                            {loadingSub ? (
                              <MDBSpinner />
                            ) : (
                              <>
                                <MDBIcon icon="times" className="mr-1" />
                                Solicitar Cancelamento
                              </>
                            )}
                          </MDBBtn>
                        ) : (
                          <MDBBtn
                            color="info"
                            rounded
                            size="sm"
                            onClick={() => this.handleSubscription()}
                          >
                            <MDBIcon icon="gem" className="mr-1" /> Tornar
                            Premium
                          </MDBBtn>
                        )}
                      </div> */}
                    </StatusContainer>
                    <Section>
                      <span>Imagem de Referência</span>
                      <FontAwesomeIcon icon="image" />
                    </Section>
                    {loadingMainPic ? (
                      <Loading />
                    ) : (
                      <div className="wrapper-main-pic">
                        <div className="main-pic">
                          <InputFile
                            id="input-upload-main-pic"
                            className="upload-main-img"
                            type="file"
                            accept="image/*"
                            onChange={this.uploadMainPic}
                          />
                          {uploadedMainPic ? (
                            <img
                              id="main-pic"
                              src={uploadedMainPic.url}
                              alt="Imagem do anúncio"
                            />
                          ) : !values.main_pic ? (
                            <div className="placeholder">
                              <div
                                className="icon"
                                title="Alterar capa do anúncio"
                                onClick={this.handleInputMainImg}
                              >
                                <FontAwesomeIcon icon="camera" />
                              </div>
                            </div>
                          ) : (
                            <img
                              id="main-pic"
                              src={values.main_pic}
                              alt="Imagem de referência do anúncio"
                            />
                          )}
                          {uploadedMainPic || values.main_pic ? (
                            <div className="overlay">
                              <div
                                className="icon"
                                title="Alterar capa do anúncio"
                                onClick={this.handleInputMainImg}
                              >
                                <FontAwesomeIcon icon="camera" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {!values.main_pic ? (
                          <span>
                            Adicione a imagem de referência do seu anúncio
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div className="fields">
                      <Section>
                        <span>Informações Básicas</span>
                        <FontAwesomeIcon icon="info-circle" />
                      </Section>
                      <FormGroup>
                        <MDBInput
                          type="text"
                          label="Título do anúncio"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          size="sm"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="description">
                          Descrição
                          <Textarea
                            name="description"
                            rows="5"
                            placeholder="Descrição do anúncio"
                            value={values.description}
                            onChange={handleChange}
                            autoComplete="new-password"
                          />
                        </label>
                      </FormGroup>
                      <Row>
                        {adType === "lodging" ? (
                          <MDBCol lg={4} md={6} sm={12}>
                            <MDBSelect
                              label="Tipo de Acomodação"
                              getValue={this.handleSelectChange(
                                "accommodation_type"
                              )}
                            >
                              <MDBSelectInput selected={"Selecione um tipo"} />
                              <MDBSelectOptions>
                                <MDBSelectOption disabled>
                                  Selecione um tipo
                                </MDBSelectOption>
                                {accommodationTypes &&
                                  accommodationTypes.map(accommodationType => (
                                    <MDBSelectOption
                                      key={accommodationType.index}
                                      value={accommodationType.value}
                                      checked={accommodationType.checked}
                                    >
                                      {accommodationType.value}
                                    </MDBSelectOption>
                                  ))}
                              </MDBSelectOptions>
                            </MDBSelect>
                          </MDBCol>
                        ) : (
                          <MDBCol lg={4} md={6} sm={12}>
                            <MDBSelect
                              label="Tipo de Negócio"
                              getValue={this.handleSelectChange("sub_type")}
                            >
                              <MDBSelectInput selected={"Selecione um tipo"} />
                              <MDBSelectOptions>
                                <MDBSelectOption disabled>
                                  Selecione um tipo
                                </MDBSelectOption>
                                {subTypes &&
                                  subTypes.map(subType => (
                                    <MDBSelectOption
                                      key={subType.index}
                                      value={subType.value}
                                      checked={subType.checked}
                                    >
                                      {subType.value}
                                    </MDBSelectOption>
                                  ))}
                              </MDBSelectOptions>
                            </MDBSelect>
                          </MDBCol>
                        )}
                      </Row>

                      {adType === "lodging" && (
                        <Row>
                          <MDBCol lg={4} md={6} sm={12}>
                            <MDBInput
                              label="Quantidade de Quartos"
                              type="number"
                              name="room_qty"
                              value={values.room_qty}
                              onChange={handleChange}
                              size="sm"
                            />
                          </MDBCol>
                          <MDBCol lg={4} md={6} sm={12}>
                            <MDBInput
                              label="Quantidade de Banheiros"
                              type="number"
                              name="restroom_qty"
                              value={values.restroom_qty}
                              onChange={handleChange}
                              size="sm"
                            />
                          </MDBCol>
                          <MDBCol lg={4} md={6} sm={12}>
                            <MDBInput
                              label="Quantidade de Pessoas"
                              type="number"
                              name="guest_qty"
                              value={values.guest_qty}
                              onChange={handleChange}
                              size="sm"
                            />
                          </MDBCol>
                          <MDBCol lg={4} md={6} sm={12}>
                            <MDBInput
                              label="Estadia Mínima"
                              type="number"
                              name="days_min_qty"
                              value={values.days_min_qty}
                              onChange={handleChange}
                              size="sm"
                            />
                          </MDBCol>
                        </Row>
                      )}

                      <Section>
                        <span>Informações p/ Contato</span>
                        <FontAwesomeIcon icon="phone-square-alt" />
                      </Section>

                      <Row>
                        <MDBCol lg={6} sm={12}>
                          <MDBInput
                            label="Telefone"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            size="sm"
                          />
                        </MDBCol>
                        {values.premium ? (
                          <>
                            <MDBCol lg={6} sm={12}>
                              <MDBInput
                                label="Número do WhatsApp"
                                name="whatsapp"
                                value={values.whatsapp}
                                onChange={handleChange}
                                size="sm"
                              />
                            </MDBCol>
                            <MDBCol lg={6} sm={12}>
                              <MDBInput
                                label="Perfil no Instagram"
                                name="instagram"
                                value={values.instagram}
                                onChange={handleChange}
                                size="sm"
                              />
                            </MDBCol>
                            <MDBCol lg={6} sm={12}>
                              <MDBInput
                                label="Página no Facebook"
                                name="facebook"
                                value={values.facebook}
                                onChange={handleChange}
                                size="sm"
                              />
                            </MDBCol>
                            <MDBCol lg={6} sm={12}>
                              <MDBInput
                                label="Website"
                                name="website"
                                value={values.website}
                                onChange={handleChange}
                                size="sm"
                              />
                            </MDBCol>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>

                      <Section>
                        <span>Valores</span>
                        <FontAwesomeIcon icon="money-bill" />
                      </Section>
                      <Row>
                        <MDBCol lg={6} md={6} sm={12}>
                          <MDBSelect
                            label="Moeda"
                            getValue={this.handleSelectChange("currency")}
                            size="sm"
                          >
                            <MDBSelectInput selected={"Selecione a moeda"} />
                            <MDBSelectOptions>
                              <MDBSelectOption disabled>
                                Selecione a moeda
                              </MDBSelectOption>
                              {currencies &&
                                currencies.map(currency => (
                                  <MDBSelectOption
                                    key={currency.index}
                                    value={currency.value}
                                    checked={currency.checked}
                                  >
                                    {currency.description}
                                  </MDBSelectOption>
                                ))}
                            </MDBSelectOptions>
                          </MDBSelect>
                        </MDBCol>
                        <MDBCol lg={6} md={6} sm={12}>
                          <label htmlFor="price" className="label-price">
                            {adType === "lodging" ? "Valor da diária" : "Valor"}
                          </label>
                          <CurrencyInput
                            name="price"
                            value={values.price}
                            onChangeEvent={this.handleChangePrice}
                            decimalSeparator=","
                            thousandSeparator="."
                            precision="2"
                            className="input-price"
                          />
                        </MDBCol>
                      </Row>

                      <Section>
                        <span>Localização</span>
                        <FontAwesomeIcon icon="map-marker-alt" />
                      </Section>
                      <WrapperMap>
                        <div className="div-map">
                          <Autocomplete
                            address={
                              values.address ? values.address : ad.address
                            }
                            lat={
                              values.latitude ? values.latitude : ad.latitude
                            }
                            lng={
                              values.longitude ? values.longitude : ad.longitude
                            }
                            onPlaceChange={this.handleLocationChange}
                          />
                        </div>
                      </WrapperMap>

                      {adType === "lodging" && (
                        <div className="fields-lodging">
                          <Section>
                            <span>Comodidades</span>
                            <FontAwesomeIcon icon="couch" />
                          </Section>
                          <Row>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="wifi">Wi-Fi</label>
                              <MDBSwitch
                                checked={values.wifi}
                                value={values.wifi}
                                onChange={this.handleSwitchChange("wifi")}
                                name="wifi"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="free_parking">
                                Estacionamento Gratuito
                              </label>
                              <MDBSwitch
                                checked={values.free_parking}
                                value={values.free_parking}
                                onChange={this.handleSwitchChange(
                                  "free_parking"
                                )}
                                name="free_parking"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="bedding">Roupa de Cama</label>
                              <MDBSwitch
                                checked={values.bedding}
                                onChange={this.handleSwitchChange("bedding")}
                                value={values.bedding}
                                name="bedding"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="towel">Roupa de Banho</label>
                              <MDBSwitch
                                checked={values.towel}
                                onChange={this.handleSwitchChange("towel")}
                                value={values.towel}
                                name="towel"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                          </Row>
                          <Section>
                            <span>Amenidades para fumantes</span>
                            <FontAwesomeIcon icon="joint" />
                          </Section>
                          <Row>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="lounge">Lounge p/ Fumantes</label>
                              <MDBSwitch
                                checked={values.lounge}
                                onChange={this.handleSwitchChange("lounge")}
                                value={values.lounge}
                                name="lounge"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="farming">
                                Cultivo na Propriedade
                              </label>
                              <MDBSwitch
                                checked={values.farming}
                                onChange={this.handleSwitchChange("farming")}
                                value={values.farming}
                                name="farming"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="smoke_outside">
                                Permitido Fumar Fora
                              </label>
                              <MDBSwitch
                                checked={values.smoke_outside}
                                onChange={this.handleSwitchChange(
                                  "smoke_outside"
                                )}
                                value={values.smoke_outside}
                                name="smoke_outside"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>

                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="smoke_inside">
                                Permitido Fumar Dentro
                              </label>
                              <MDBSwitch
                                checked={values.smoke_inside}
                                onChange={this.handleSwitchChange(
                                  "smoke_inside"
                                )}
                                value={values.smoke_inside}
                                name="smoke_inside"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>

                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="bong">Fornece Bong</label>
                              <MDBSwitch
                                checked={values.bong}
                                onChange={this.handleSwitchChange("bong")}
                                value={values.bong}
                                name="bong"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>

                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="accessories">
                                Acessórios Disponíveis
                              </label>
                              <MDBSwitch
                                checked={values.accessories}
                                onChange={this.handleSwitchChange(
                                  "accessories"
                                )}
                                value={values.accessories}
                                name="accessories"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                          </Row>
                          <Section>
                            <span>Recursos Especiais</span>
                            <FontAwesomeIcon icon="dog" />
                          </Section>
                          <Row>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="animals_allowed">
                                Permitido Animais
                              </label>
                              <MDBSwitch
                                checked={values.animals_allowed}
                                onChange={this.handleSwitchChange(
                                  "animals_allowed"
                                )}
                                value={values.animals_allowed}
                                name="animals_allowed"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="pets">Pets na Propriedade</label>
                              <MDBSwitch
                                checked={values.pets}
                                onChange={this.handleSwitchChange("pets")}
                                value={values.pets}
                                name="pets"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="events">
                                Apropriado para Eventos
                              </label>
                              <MDBSwitch
                                checked={values.events}
                                onChange={this.handleSwitchChange("events")}
                                value={values.events}
                                name="events"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                            <MDBCol lg={3} md={4} sm={6}>
                              <label htmlFor="accessible">
                                Acessível para Cadeirantes
                              </label>
                              <MDBSwitch
                                checked={values.accessible}
                                onChange={this.handleSwitchChange("accessible")}
                                value={values.accessible}
                                name="accessible"
                                labelLeft=""
                                labelRight=""
                              />
                            </MDBCol>
                          </Row>
                        </div>
                      )}
                    </div>
                  </div>
                </MDBTabPane>
                <MDBTabPane tabId="2" role="tabpanel">
                  {values.premium ? (
                    <MediaList medias={medias} id={values.id} />
                  ) : (
                    <PremiumSectionAlert>
                      <span>Essa seção é somente para anúncios Premium</span>
                    </PremiumSectionAlert>
                  )}
                </MDBTabPane>
                <MDBTabPane tabId="3" role="tabpanel">
                  <ReviewList ad={ad._id} />
                </MDBTabPane>
                {!!errors.title || !!errors.address ? (
                  <MDBContainer>
                    {!!errors.title ? (
                      <MDBAlert color="danger" dismiss>
                        <strong>{errors.title}</strong>
                      </MDBAlert>
                    ) : (
                      ""
                    )}
                    {!!errors.address ? (
                      <MDBAlert color="danger" dismiss>
                        <strong>{errors.address}</strong>
                      </MDBAlert>
                    ) : (
                      ""
                    )}
                  </MDBContainer>
                ) : (
                  ""
                )}
              </MDBTabContent>
            </Form>
          </Panel>

          <div className="buttons">
            <Link
              to={`/${this.props.adType}`}
              className="btn-danger btn Ripple-parent"
            >
              <MDBIcon icon="times" />
              <span>Cancelar</span>
            </Link>
            <MDBBtn color="primary" onClick={handleSubmit}>
              <FontAwesomeIcon icon="check" />
              <span>Salvar</span>
            </MDBBtn>
          </div>

          <Dialog
            open={this.state.openDialog}
            onClose={this.handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Tem certeza que deseja cancelar sua assinatura?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Os dados de anúncio premium deixarão de ser exibidos no app. O
                cancelamento será finalizado em até 24h.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={() => this.handleCancelSubscription()}
                color="primary"
                autoFocus
              >
                Confimar
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      );
    } else {
      return "";
    }
  }
}

AdForm.defaultProps = {
  ad: null,
  medias: null,
  uploadedMainPic: null
};

const mapStateToProps = state => ({
  ad: state.ad.data,
  medias: state.ad.medias,
  loadingAd: state.ad.loading,
  uploadedMainPic: state.uploadAd.data,
  loadingMainPic: state.uploadAd.loading,
  loadingSub: state.subscription.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...UploadAdActions, ...AdActions, ...SubscriptionActions },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ values, ad }) =>
      values || {
        id: ad ? ad._id : "",
        type: ad && ad.type ? ad.type : "",
        main_pic: ad && ad.main_pic ? ad.main_pic : "",
        title: ad && ad.title !== "Sem Título" ? ad.title : "",
        sub_type: ad && ad.sub_type ? ad.sub_type : "",
        active: ad && ad.active ? ad.active : false,
        activation: ad && ad.active ? ad.active : false,
        is_valid:
          ad &&
          ad.main_pic &&
          ad.title &&
          ad.description &&
          (ad.address || ad.city)
            ? true
            : false,
        premium:
          ad && ad.premium && !ad.cancelation_requested ? ad.premium : false,
        description: ad && ad.description ? ad.description : "",
        currency: ad && ad.currency ? ad.currency : "",
        price: ad && ad.price ? ad.price : 0,
        phone: ad && ad.phone ? ad.phone : "",
        email: ad && ad.email ? ad.email : "",
        whatsapp: ad && ad.whatsapp ? ad.whatsapp : "",
        instagram: ad && ad.instagram ? ad.instagram : "",
        facebook: ad && ad.facebook ? ad.facebook : "",
        latitude: ad && ad.latitude ? ad.latitude : 0,
        longitude: ad && ad.longitude ? ad.longitude : 0,
        address: ad && ad.address ? ad.address : "",
        city: ad && ad.city ? ad.city : "",
        country: ad && ad.country ? ad.country : "",
        accommodation_type:
          ad && ad.accommodation_type ? ad.accommodation_type : "",
        room_qty: ad && ad.room_qty ? ad.room_qty : 0,
        restroom_qty: ad && ad.restroom_qty ? ad.restroom_qty : 0,
        guest_qty: ad && ad.guest_qty ? ad.guest_qty : 0,
        days_min_qty: ad && ad.days_min_qty ? ad.days_min_qty : 0,
        cleaning_fee: ad && ad.cleaning_fee ? ad.cleaning_fee : 0,
        wifi: ad && ad.wifi ? ad.wifi : false,
        free_parking: ad && ad.free_parking ? ad.free_parking : false,
        bedding: ad && ad.bedding ? ad.bedding : false,
        towel: ad && ad.towel ? ad.towel : false,
        lounge: ad && ad.lounge ? ad.lounge : false,
        farming: ad && ad.farming ? ad.farming : false,
        smoke_outside: ad && ad.smoke_outside ? ad.smoke_outside : false,
        smoke_inside: ad && ad.smoke_inside ? ad.smoke_inside : false,
        bong: ad && ad.bong ? ad.bong : false,
        accessories: ad && ad.accessories ? ad.accessories : false,
        animals_allowed: ad && ad.animals_allowed ? ad.animals_allowed : false,
        pets: ad && ad.pets ? ad.pets : false,
        events: ad && ad.events ? ad.events : false,
        accessible: ad && ad.accessible ? ad.accessible : false,
        posted_date: ad && ad.posted_date ? ad.posted_date : null,
        cancelation_requested:
          ad && ad.cancelation_requested ? ad.cancelation_requested : false
      },
    handleSubmit: (values, { props }) => props.setAdRequest(values),

    validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    validateOnBlur: false
  })
)(AdForm);
