import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  signInRequest: ["email", "password"],
  signInSuccess: ["token"],
  signOut: null,
  signUpRequest: [
    "first_name",
    "last_name",
    "email",
    "password",
    "password_confirmation"
  ],
  facebookLoginRequest: ["data"],
  facebookLoginSuccess: ["token"]
});

const INITIAL_STATE = {
  signedIn: !!localStorage.getItem("@Micasa420:token"),
  token: localStorage.getItem("@Micasa420:token") || null,
  loading: false
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  signedIn: true,
  loading: false,
  token: action.token
});

const logout = (state = INITIAL_STATE) => ({
  ...state,
  signedIn: false,
  token: null
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FACEBOOK_LOGIN_REQUEST]: request,
  [Types.SIGN_IN_SUCCESS]: success,
  [Types.SIGN_OUT]: logout
});

export const AuthTypes = Types;

export const AuthActions = Creators;
