const getCurrencies = currency => {
  return [
    {
      index: 0,
      value: "US$",
      description: "Dólar - US$",
      checked: currency === "US$"
    },
    {
      index: 1,
      value: "€",
      description: "Euro - €",
      checked: currency === "€"
    },
    {
      index: 2,
      value: "$U",
      description: "Peso Uruguaio - $U",
      checked: currency === "$U"
    },
    {
      index: 3,
      value: "CL$",
      description: "Peso Chileno - CL$",
      checked: currency === "CL$"
    },
    {
      index: 4,
      value: "AR$",
      description: "Peso Argentino - AR$",
      checked: currency === "AR$"
    },
    {
      index: 5,
      value: "R$",
      description: "Real Brasileiro - R$",
      checked: currency === "R$"
    }
  ];
};

export default getCurrencies;
