import styled from "styled-components";

const Textarea = styled.textarea`
  width: 100%;
  background: none;
  border: 2px solid rgba(63, 81, 181, 0.3);
  margin-top: 5px;
  padding: 5px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  background: #ffffff;

  &:hover,
  &:focus,
  &:active {
    border: 2px solid rgba(63, 81, 181, 0.8);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border: 2px solid rgba(63, 81, 181, 0.8);
    box-shadow: none;
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    -webkit-text-fill-color: rgba(63, 81, 181, 0.8) !important;
  }
`;

export default Textarea;
