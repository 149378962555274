import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
`;

export const Spinner = styled.img`
  margin: 15px;
  height: 80px;
  width: 80px;
  animation: ${rotate360} 2s linear infinite;
`;
