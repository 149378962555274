import React from "react";

import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";
import ProfileForm from "../../components/Profile";

import { Container, Wrapper, Content, Title } from "../../styles/pages";

const Profile = () => (
  <Container>
    <TopBar />
    <SideBar />
    <Wrapper>
      <Content>
        <Title>Meus Dados</Title>

        <ProfileForm />
      </Content>
    </Wrapper>
  </Container>
);

export default Profile;
