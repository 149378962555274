import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 100%;
  background: #f5f5f5;
`;

export const Wrapper = styled.div`
  margin-left: 210px;
  margin-top: 20px;
  padding: 10px;
  display: flex;

  @media (max-width: 1092px) {
    margin-left: 60px;
  }

  @media (max-width: 770px) {
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 25px;
  width: 100%;

  ol.breadcrumb {
    background-color: transparent !important;
    color: #3d5170;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 22px;
    letter-spacing: 1.11px;
    font-weight: 300;

    @media (max-width: 770px) {
      padding: 0;
      line-height: 8px;
      font-size: 7px;
    }
  }

  @media (max-width: 837px) {
    align-items: center;
  }

  @media (max-width: 770px) {
    margin-top: 50px;
    padding: 0;
  }

  .container {
    @media (max-width: 770px) {
      padding: 0;
    }
  }

  .h2 {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 25px;

    @media (max-width: 550px) {
      font-size: 1.5em;
    }

    @media (max-width: 400px) {
      font-size: 1em;
    }
  }

  .centralized-text {
    font-size: 1.1em;
    text-align: center;

    @media (max-width: 550px) {
      text-align: justify;
      font-size: 0.95em;
    }

    @media (max-width: 400px) {
      text-align: justify;
      font-size: 0.9em;
    }

    .price {
      font-weight: 600;
    }
  }
`;

export const Title = styled.h4`
  color: #3d5170;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 1.11px;
  font-weight: 300;
`;

export const SubTitle = styled.h2`
  color: #3d5170;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 837px) {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
