import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as toastr } from "react-redux-toastr";
import { reducer as auth } from "./auth";
import { reducer as profile } from "./profile";
import { reducer as uploadAd } from "./upload-ad";
import { reducer as uploadAvatar } from "./upload-avatar";
import { reducer as uploadMedia } from "./upload-media";
import { reducer as ad } from "./ad";
import { reducer as adReview } from "./ad-review";
import { reducer as subscription } from "./subscription";

export default history =>
  combineReducers({
    auth,
    profile,
    uploadAd,
    uploadAvatar,
    uploadMedia,
    toastr,
    ad,
    adReview,
    subscription,
    router: connectRouter(history)
  });
