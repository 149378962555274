import { call, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import api from "../../services/api";
import handleError from "./error-handler";

import { AdReviewActions } from "../ducks/ad-review";

export function* getAdReview({ id }) {
  try {
    const response = yield call(api.get, `/review/${id}`);
    if (response.data) {
      yield put(AdReviewActions.getAdReviewSuccess(response.data.reviews));
    } else {
      yield put(handleError(response));
    }
    yield put(AdReviewActions.getAdReviewComplete());
  } catch (error) {
    yield put(AdReviewActions.getAdReviewComplete());
    yield put(handleError());
  }
}

export function* setAdReview({ id, reply }) {
  const response = yield call(api.put, `/review/${id}`, { reply });

  if (response.data.ok) {
    toastr.success("", "Resposta salva");

    //yield put(AdReviewActions.setAdReviewSuccess(response.data.reviews));
  } else {
    yield put(AdReviewActions.setAdReviewFailure());
    yield put(handleError(response));
  }
}
