import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Rater from "react-rater";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import { AdActions } from "../../../store/ducks/ad";

import api from "../../../services/api";

import SearchField from "../../../styles/components/SearchField";

import Loading from "../../Loading";

import { MDBBtn, MDBIcon } from "mdbreact";

import { Container, Panel, Ad } from "./styles";

class List extends Component {
  state = {
    currentItems: [],
    items: [],
    loading: true,
    openDialog: false,
    idItem: ""
  };

  async componentDidMount() {
    const { adType } = this.props;
    await api
      .get(`/adsAdmin/${adType === "other" ? "others" : adType}`)
      .then(response => {
        this.setState({
          currentItems: response.data.ads,
          items: response.data.ads,
          loading: false
        });
      });
  }

  handleDialogOpen = itemId => {
    this.setState({ idItem: itemId });
    this.setState({ openDialog: true });
  };

  handleDialogClose = e => {
    e.preventDefault();
    this.setState({ openDialog: false });
  };

  clearField = e => {
    e.preventDefault();

    const input = document.querySelector(".field");

    input.value = "";

    const resetList = this.state.currentItems;

    this.setState({ items: resetList });
  };

  handleFilter = e => {
    let updatedList = this.state.currentItems;
    updatedList = updatedList.filter(item => {
      return (
        item.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1 ||
        item.sub_type.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });

    this.setState({ items: updatedList });
  };

  handleDelete = itemId => {
    api.delete(`/deleteAd/${itemId}`).then(() => {
      const items = this.state.items.filter(item => item._id !== itemId);

      toastr.success("", "Anúncio excluído com sucesso");
      this.setState({ items: items });
      this.setState({ openDialog: false });
    });
  };

  handleNewAd = e => {
    e.preventDefault();

    const { createAdRequest, adType } = this.props;

    createAdRequest(adType);
  };

  handleOpenAd = (ad, premium) => {
    const { adType } = this.props;
    this.props.history.push({
      pathname: `/${adType}`,
      search: { ad: ad, premium: premium }
    });
  };

  render() {
    const { items } = this.state;

    const { adType } = this.props;

    return (
      <Container>
        <SearchField>
          <input
            type="text"
            placeholder="Pesquisar..."
            className="field"
            onChange={this.handleFilter}
          />
          <div className="icons-container">
            <div className="icon-search" />
            <div className="icon-close" onClick={this.clearField}>
              <div className="x-up" />
              <div className="x-down" />
            </div>
          </div>
        </SearchField>
        <div className="new-button">
          <MDBBtn color="unique" onClick={this.handleNewAd}>
            <FontAwesomeIcon icon="plus" />
            <span>NOVO ANÚNCIO</span>
          </MDBBtn>
        </div>
        {this.state.loading ? (
          <Panel>
            <Loading />
          </Panel>
        ) : items.length === 0 ? (
          <Panel>
            <p>Nenhum registro foi encontrado</p>
          </Panel>
        ) : (
          <Panel>
            <ul>
              {this.state.items.map(item => {
                return (
                  <li key={item._id}>
                    <Ad theme={{ active: item.active, premium: item.premium }}>
                      <div className="item-data">
                        <div className="item-pic">
                          <Link
                            to={`/${adType}/${item._id}/${
                              item.premium ? "premium" : "free"
                            }`}
                          >
                            {item.main_pic ? (
                              <img
                                src={item.main_pic}
                                alt="Imagem de capa do anúncio"
                              />
                            ) : (
                              <span>SEM IMAGEM</span>
                            )}
                          </Link>
                          <span className="posted-date">
                            {item.formatted_posted_date
                              ? `Postado em: ${item.formatted_posted_date}`
                              : ""}
                          </span>
                        </div>
                        <div className="wrapper">
                          <div className="item-info">
                            <div className="item-title">
                              <Link
                                to={`/${adType}/${item._id}/${
                                  item.premium ? "premium" : "free"
                                }`}
                              >
                                {item.title}
                              </Link>
                              {item.premium ? (
                                <MDBIcon icon="gem" className="icon" />
                              ) : (
                                ""
                              )}
                            </div>

                            <span className="type">
                              {item.type === "lodging"
                                ? item.accommodation_type
                                : item.sub_type}
                            </span>
                          </div>

                          <div className="review">
                            {item.reviews_count > 0 ? (
                              <div className="rating-reviews">
                                <Rater
                                  rating={item.average_rating}
                                  total={5}
                                  interactive={false}
                                />
                                <span className="total-reviews">
                                  {`${item.reviews_count} reviews`}
                                </span>
                              </div>
                            ) : (
                              <div className="rating-reviews">
                                <Rater
                                  rating={0}
                                  total={5}
                                  interactive={false}
                                />
                                <span className="total-reviews">
                                  {`Sem reviews`}
                                </span>
                              </div>
                            )}

                            <div className="info">
                              <div className="status">
                                <span className="status">
                                  {item.active ? "Ativo" : "Inativo"}
                                </span>
                              </div>
                            </div>

                            <div className="actions">
                              {!item.premium ? (
                                <MDBBtn
                                  onClick={() =>
                                    this.handleDialogOpen(item._id)
                                  }
                                  size="sm"
                                  floating
                                  color="danger"
                                >
                                  <MDBIcon icon="trash-alt" />
                                </MDBBtn>
                              ) : (
                                ""
                              )}
                              <Link
                                to={`/${adType}/${item._id}/${
                                  item.premium ? "premium" : "free"
                                }`}
                                className="btn-primary btn Ripple-parent btn-floating btn-sm"
                              >
                                <MDBIcon icon="edit" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Ad>
                  </li>
                );
              })}
            </ul>
          </Panel>
        )}

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirma a exclusão?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Caso confirme, o registro será excluído permanentemente.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={() => this.handleDelete(this.state.idItem)}
              color="primary"
              autoFocus
            >
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.ad.loading
});

const mapDispatchToProps = dispatch => bindActionCreators(AdActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(List);
