import { createGlobalStyle } from "styled-components";

import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Montserrat');

  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #f5f5f5 !important;
    color: #333333 !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px !important;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  html, body, #root {
    height: 100% !important;
  }

  input, button {
    font-family: 'Montserrat', sans-serif !important;
  }

  button {
    cursor: pointer;
  }
`;
