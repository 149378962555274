import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;

  ul {
    width: 100%;
  }

  div.img-placeholder {
    height: 100%;
    width: 100%;
    transition: 0.3s ease;
    background-color: #fff;
    border: 2px dashed rgba(38, 38, 38, 0.4);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div.icon {
      cursor: pointer;
      color: rgba(38, 38, 38, 0.4);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      svg {
        font-size: 45px;
      }

      span {
        font-size: 1em;
        text-align: center;
      }

      &:hover {
        color: rgb(38, 38, 38);
      }
    }
  }

  .img-tile-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .img-list {
    height: 250px;

    width: 25%;

    @media (max-width: 1300px) {
      width: 33% !important;
    }

    @media (max-width: 1100px) {
      width: 50% !important;
    }

    @media (max-width: 550px) {
      width: 100% !important;
    }
  }
`;
