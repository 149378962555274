import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrActions } from "react-redux-toastr";
import api from "../../services/api";

import { AuthActions } from "../ducks/auth";

export function* signIn({ email, password }) {
  try {
    const response = yield call(api.post, "signin", {
      email,
      password
    });

    localStorage.setItem("@Micasa420:userName", response.data.username);
    localStorage.setItem("@Micasa420:token", response.data.token);

    yield put(AuthActions.signInSuccess(response.data.token));
    yield put(push("/dashboard"));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: "error",
        title: "Falha no login",
        message: "Verifique se os dados informados estão corretos"
      })
    );
  }
}

export function* facebookLogin(action) {
  try {
    const response = yield call(api.post, "fbLogin", action.data);

    localStorage.clear();

    localStorage.setItem("@Micasa420:token", response.data.token);
    localStorage.setItem("@Micasa420:userName", response.data.username);

    if (response.data.profile_pic) {
      localStorage.setItem("@Micasa420:profileUrl", response.data.profile_pic);
    }

    yield put(AuthActions.signInSuccess(response.data.token));

    yield put(push("/dashboard"));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: "error",
        title: "Falha no login",
        message: "Verifique se os dados informados estão corretos"
      })
    );
  }
}

export function* signUp({
  first_name,
  last_name,
  email,
  password,
  password_confirmation,
  document_number
}) {
  if (password !== password_confirmation) {
    yield put(
      toastrActions.add({
        type: "error",
        title: "Falha no cadastro",
        message: "A confirmação da senha não está correta"
      })
    );
  } else {
    try {
      const response = yield call(api.post, "signup", {
        first_name,
        last_name,
        email,
        password,
        document_number
      });

      localStorage.setItem(
        "@Micasa420:userName",
        `${response.data.user.first_name} ${response.data.user.last_name}`
      );

      yield put(AuthActions.signInSuccess(response.data.token));
      yield put(push("/dashboard"));
    } catch (err) {
      yield put(
        toastrActions.add({
          type: "error",
          title: "Falha no cadastro",
          message: "Verifique se os dados informados estão válidos"
        })
      );
    }
  }
}

export function* signOut() {
  localStorage.removeItem("@Micasa420:token");
  localStorage.removeItem("@Micasa420:userName");

  yield put(push("/"));
}
