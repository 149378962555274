import React from "react";

import { MDBContainer } from "mdbreact";

import TopBarStatic from "../../components/TopBarStatic";

import { Container } from "../../styles/pages";

import IMG_HEADER from "../../assets/images/header_home.png";

export default function Home(props) {
  return (
    <Container>
      <TopBarStatic />
      <div style={{ marginTop: "55px" }}>
        <img src={IMG_HEADER} alt="" />
      </div>

      <MDBContainer style={{ marginTop: "25px" }}>
        <h1>Cookies e tecnologias similares no Micasa420</h1>
        <h2>O Micasa420 usa cookies?</h2>
        <p>
          Como descrito na seção &quot;E os cookies e tecnologias
          similares?&quot; de nossa Política de Privacidade, nós, e nossos
          agentes, usamos tecnologias de cookies, pixels e armazenamento local
          (como em seu navegador ou aparelho, que é semelhante a um cookie, mas
          retêm mais informações) em nosso site. Cookies são pequenos fragmentos
          de dados armazenados em seu computador, telefone celular ou outro
          aparelho. Pixels são pequenos blocos de código em páginas da web e em
          e- mails que permitem outro servidor a medir a visualização de uma
          página da web, e são frequentemente usados junto com cookies.
          Armazenamento local é uma tecnologia padrão da indústria que permite
          que um site ou aplicativo armazenar e obter dados no computador,
          telefone celular ou outro aparelho de uma pessoa.
        </p>
        <h2>Para que são usados cookies e tecnologias similares?</h2>
        <p>
          Nós e nossos agentes usamos essas tecnologias para tornar os Serviços
          Micasa420 mais fáceis e rápidos de usar, ativar recursos e armazenar
          informações sobre você (inclusive em seu aparelho ou no cache de seu
          navegador) e seu uso dos Serviços; monitorar e entender o uso de
          nossos produtos e serviços; e para proteger você, os outros e a
          Micasa420. Nós e nossos agentes usamos essas tecnologias para
          fornecer, compreender e melhorar a publicidade dirigida aos usuários
          dos Serviços Micasa420 em nome do Micasa420 e outros (como entidades
          que tenham produtos patrocinados ou promovidos pelo Micasa420) nos
          aplicativos e sites Micasa420 e em outros sites que você visita, com
          base em sua atividade nos Serviços.
        </p>
        <p>
          O Micasa420 usa cookies e tecnologias semelhantes por variados
          motivos, como:
        </p>
        <p>
          * Autenticação – a Micasa420 usa cookies, armazenamento local e
          tecnologias semelhantes para reconhecer se você está conectado aos
          nossos Serviços, para que possamos exibir as informações corretas,
          entender sua utilização dos Serviços e personalizar sua experiência.
        </p>
        <p>
          * Segurança - a Micasa420 usa cookies, armazenamento local e
          tecnologias semelhantes para dar suporte ou habilitar nossos recursos
          de segurança, e para nos ajudar a detectar atividades maliciosas e
          violações de nossos Termos de Serviço.
        </p>
        <p>
          * Preferências, recursos e serviços– a Micasa420 usa cookies,
          armazenamento local e tecnologias semelhantes para saber suas
          preferências de idioma e de comunicação e também para ajudá-lo a
          preencher formulários nos Serviços.
        </p>
        <p>
          * Desempenho, Estatística e Pesquisa – por vezes usamos outros
          prestadores de serviços para nos ajudar a providenciar determinados
          produtos e serviços, para nos fornecer informações sobre o uso dos
          Serviços, visando melhorá-los e torná-los atraentes para o maior
          número possível de usuários. Por exemplo, o Micasa420 usa prestadores
          analíticos terceirizados, como Google Analytics, para entender melhor
          quem está usando o serviço e como as pessoas o utilizam. Como parte da
          prestação destes serviços, os prestadores de serviços podem coletar e
          armazenar informações anônimas, tais como a hora da visita, páginas
          visitadas, tempo gasto em cada página do site, o endereço IP e o
          sistema operacional usado. Leia as políticas de privacidade para
          verificar se está satisfeito com a maneira pela qual eles usam
          cookies.
        </p>
        <h2>Cookies podem ser desativados?</h2>
        <p>
          A maioria dos navegadores tem uma opção de desativação do recurso de
          cookies, que impede que o navegador aceite novos cookies, além de
          (dependendo da sofisticação do software do navegador) permitir que
          você decida, de várias maneiras, se aceita cada novo cookie. Para
          obter mais informações sobre cookies e como recusá-los, 
          <a href="http://www.allaboutcookies.org/">clique aqui</a>. O Google
          desenvolveu um 
          <a href="https://tools.google.com/dlpage/gaoptout">plugin</a>  para o
          navegador web para que você possa optar por não ativar o software de
          monitoramento do Google Analytics. Você também pode optar por não
          receber anúncios de comportamento provenientes do Micasa420 quando
          estiver fora dos aplicativos e sites Micasa420, basta seguir as
          instruções no rodapé do e-mail. No entanto, lembre-se de que a
          limitação da capacidade de sites para implementar cookies pode piorar
          sua experiência como usuário e, em alguns casos, os Serviços não irão
          funcionar adequadamente sem cookies, armazenamento local e tecnologias
          semelhantes.
        </p>
      </MDBContainer>
    </Container>
  );
}
