import React, { Component } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";

import { MDBSpinner } from "mdbreact";

import {
  HotelIcon,
  BusinessIcon,
  ExperienceIcon,
  MedicalIcon,
  OthersIcon
} from "../../utils/materialiconslib";

import { Container, CardContent, CardIcon, CardText } from "./styles";

class Dashboard extends Component {
  state = {
    totals: [],
    loading: true
  };

  async componentDidMount() {
    await api.get("/dashboard").then(response => {
      this.setState({
        totals: response.data.totals,
        loading: false
      });
    });
  }

  render() {
    const { loading, totals } = this.state;

    if (loading) {
      return (
        <Container>
          <MDBSpinner />
        </Container>
      );
    }
    return (
      <Container>
        <Link to="/lodgings">
          <CardContent hotel>
            <CardIcon>
              <HotelIcon />
            </CardIcon>

            <CardText>
              <span className="number">{totals.lodging}</span>
              <span className="text">Hospedagens</span>
            </CardText>
          </CardContent>
        </Link>
        <Link to="/businesses">
          <CardContent business>
            <CardIcon>
              <BusinessIcon />
            </CardIcon>

            <CardText>
              <span className="number">{totals.business}</span>
              <span className="text">Negócios</span>
            </CardText>
          </CardContent>
        </Link>
        <Link to="/experiences">
          <CardContent experience>
            <CardIcon>
              <ExperienceIcon />
            </CardIcon>

            <CardText>
              <span className="number">{totals.experience}</span>
              <span className="text">Experiências</span>
            </CardText>
          </CardContent>
        </Link>
        <Link to="/medicals">
          <CardContent medical>
            <CardIcon>
              <MedicalIcon />
            </CardIcon>

            <CardText>
              <span className="number">{totals.medical}</span>
              <span className="text">Saúde</span>
            </CardText>
          </CardContent>
        </Link>
        <Link to="/others">
          <CardContent others>
            <CardIcon>
              <OthersIcon />
            </CardIcon>

            <CardText>
              <span className="number">{totals.others}</span>
              <span className="text">Diversos</span>
            </CardText>
          </CardContent>
        </Link>
      </Container>
    );
  }
}

export default Dashboard;
