import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withFormik } from "formik";

import Card from "react-credit-cards";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from "../../utils/creditcardFormats";

import { AdActions } from "../../store/ducks/ad";
import { SubscriptionActions } from "../../store/ducks/subscription";

import { MDBCol, MDBBtn, MDBIcon, MDBSpinner } from "mdbreact";

import { Container, Panel, Section } from "./styles";

import validationSchema from "./validationSchema";

class CheckoutForm extends Component {
  state = {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    focused: "",

    cpf: "",
    cnpj: "",
    phone_mask: "",
    zipcode: ""
  };

  async componentDidMount() {
    await this.props.getAdRequest(this.props.id);
  }

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleInputChange = ({ target }) => {
    const { setFieldValue } = this.props;
    setFieldValue(`${[target.name]}`, target.value);

    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleInputMaskChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.form.reset();
  };

  getAdType = type => {
    if (type === "lodging") {
      return "Hospedagem";
    } else if (type === "experience") {
      return "Experiência";
    } else if (type === "business") {
      return "Negócios";
    } else if (type === "medical") {
      return "Saúde";
    } else {
      return "Diversos";
    }
  };

  render() {
    const { ad, loading, handleSubmit } = this.props;
    const { name, number, expiry, cvc, focused } = this.state;

    return (
      <Container>
        <Panel>
          <form ref={c => (this.form = c)} onSubmit={handleSubmit}>
            <Section>
              <span>Dados do Anúncio</span>
              <MDBIcon icon="ad" size="lg" />
            </Section>
            {loading || !ad ? (
              <MDBSpinner />
            ) : (
              <div className="info-ad">
                <span>Tipo: </span>
                <p>{`${this.getAdType(ad.type)}`}</p>
                <span>Título: </span>
                <p>{ad.title}</p>
              </div>
            )}

            <Section>
              <span>Dados p/ Pagamento</span>
              <MDBIcon icon="money-bill" size="lg" />
            </Section>
            <div className="payment-info">
              <div className="credit-card">
                <Card
                  number={number}
                  name={name}
                  expiry={expiry}
                  cvc={cvc}
                  focused={focused}
                  placeholders={{ name: "Seu Nome Aqui" }}
                />
              </div>

              <div className="form-container">
                <div className="form-wrapper">
                  <MDBCol lg={6} md={6} sm={12}>
                    <input
                      type="tel"
                      name="number"
                      placeholder="Número do Cartão"
                      pattern="[\d| ]{16,22}"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </MDBCol>
                  <MDBCol lg={6} md={6} sm={12}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Nome no Cartão"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </MDBCol>
                  <MDBCol lg={6} md={6} sm={12}>
                    <input
                      type="tel"
                      name="expiry"
                      placeholder="Validade (MM/AA)"
                      pattern="\d\d/\d\d"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </MDBCol>
                  <MDBCol lg={6} md={6} sm={12}>
                    <input
                      type="tel"
                      name="cvc"
                      placeholder="Código de Segurança"
                      pattern="\d{3,4}"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </MDBCol>
                </div>
                <div className="form-button">
                  <MDBBtn type="submit" color="primary">
                    <MDBIcon icon="check" className="mr-1" /> Confirmar
                    Assinatura
                  </MDBBtn>
                </div>
              </div>
            </div>
          </form>
        </Panel>
      </Container>
    );
  }
}

CheckoutForm.defaultProps = {
  ad: null
};

const mapStateToProps = state => ({
  ad: state.ad.data,
  loading: state.ad.loading,
  loadingPayment: state.subscription.loading,
  user: state.ad.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...SubscriptionActions, ...AdActions }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ values, id, user }) =>
      values || {
        ad: id,
        number: "",
        name: "",
        expiry: "",
        cvc: "",
        focused: ""
      },
    handleSubmit: (values, { props, resetForm }) => {
      props.setSubscriptionRequest(values);
      resetForm();
    },

    validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    validateOnBlur: false
  })
)(CheckoutForm);
