import styled from "styled-components";

export const Container = styled.div`
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .btn {
    font-size: 1.25em;

    @media (max-width: 500px) {
      font-size: 1em;
    }
  }

  .button-fb-login {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
    border-radius: 3px;
    transition: background-color 0.15s ease;
    background: #4267b2;
    border: 0;
    color: #fff;
    padding: 15px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;

    @media (max-width: 500px) {
      font-size: 1em;
    }

    i {
      margin-right: 15px;
    }
  }

  div.form-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .form-check {
    padding-left: 0 !important;
  }

  .label-check {
    text-align: justify;
    font-size: 0.8em;

    a {
      font-size: 1em;
    }
  }

  .label-terms {
    font-size: 12px;
    font-weight: 500;
    margin: 12px 0;
  }

  .mr-5 {
    margin-right: 0 !important;
  }
`;

export const SignForm = styled.form`
  z-index: 1;
  background-color: #ffffff;
  opacity: 0.95;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  h1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 5px 0 10px;
  }

  button {
    margin: 10px 0 0;
  }

  a {
    span {
      color: #000;
    }

    color: #0081ff;
    text-decoration: none;
    font-size: 12px;
    margin-top: 15px;
    line-height: 15px;
    font-weight: 500;

    span {
      font-size: 12px;
      font-weight: 500;
    }

    &:hover {
      color: #006cff;
    }
  }

  @media (max-width: 479px) {
    width: 350px;
  }
`;

export const Logo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  img {
    max-width: 60%;
    vertical-align: middle;
    display: inline-block;
  }
`;

export const ErrorBox = styled.div`
  height: 42px;
  span {
    color: #df4755;
    font-size: 13px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 20px 0;

  label {
    width: 100%;
    display: block;
    font-weight: bold;
    font-size: 14px;
    text-align: left;

    input {
      margin-top: 5px;
      width: 100%;
      background: none;
      border-style: none;
      border-bottom: 2px solid #38ef7d;
      padding: 10px;
      font-size: 14px;
      background: #ffffff;
      color: #052d2a;
    }

    input:hover,
    input:focus,
    input:active {
      border-bottom: 2px solid #052d2a;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      border-style: none;
      border-bottom: 2px solid #052d2a;
      box-shadow: none;
      -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
      -webkit-text-fill-color: #052d2a !important;
    }
  }
`;
