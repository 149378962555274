import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  position: fixed;
  z-index: 3;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  box-shadow: 1px 1px 5px -1px hsla(0, 0%, 63%, 0.69);
`;

export const Logo = styled.a`
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;

  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
`;

export const User = styled.div`
  max-width: 310px;

  font-size: 13px;
  padding: 6px 7px 6px 26px;

  @media (max-width: 769px) {
    display: none;
  }

  div.wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 35px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin-right: 5px;
  }

  span {
    max-width: 200px;
    display: inline-block;
    margin-left: 5px;
    color: #3d5170;
  }

  div.menu {
    z-index: 3;
    background: #fff;
  }

  div.btn-user {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    padding: 1px 0px 0px;
    background-color: transparent;
    color: #3d5170;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 7px;
      margin-bottom: 2px;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }

  div.btn-user.active {
    background-color: #f6f6f6;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ToggleButton = styled.div`
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: ${props => (props.active ? "#fff" : "#88334d")};
  background: ${props => (props.active ? "#88334d" : "#fff")};
  border: none;

  @media (min-width: 770px) {
    display: none;
  }
`;

export const NavWeb = styled.div`
  position: fixed;
  z-index: 3;
  width: 175px;
  margin-left: -106px;
  padding: 10px 0px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 6px -3px hsla(0, 0%, 63%, 0.79);
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    display: none;
  }

  a {
    display: inline-block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 5px;
    font-size: 12px;
    border-left: 3px solid #fff;
    color: #3d5170;
    text-decoration: none;
    align-items: center;

    &:hover {
      border-left: 3px solid #88334d;
      background-color: rgba(136, 51, 77, 0.1);
    }

    svg {
      width: 20%;
      font-size: 20px;
      margin-left: 10px;
      margin-right: 5px;
      align-items: center;
      vertical-align: middle;
      display: inline-block;
    }

    span {
      vertical-align: middle;
      display: inline-block;
    }
  }

  a.active {
    border-left: 3px solid #88334d;
    background-color: rgba(136, 51, 77, 0.1);

    svg {
      color: #88334d;
    }
  }
`;
