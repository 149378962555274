import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { MDBNav, MDBNavItem, MDBIcon } from "mdbreact";

import Form from "../Form";

import { Container } from "./styles";

class Tabs extends Component {
  state = {
    activeItem: "1"
  };

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  render() {
    const { id, adType, premium } = this.props;

    return (
      <Container>
        <MDBNav tabs className={"nav-justified dusty-grass-gradient"}>
          <MDBNavItem>
            <NavLink
              to="#"
              active={this.state.activeItem === "1"}
              onClick={this.toggle("1")}
              role="tab"
              className={
                this.state.activeItem === "1" ? "nav-link active " : "nav-link"
              }
            >
              <MDBIcon icon="info" /> <span>Dados do Anúncio</span>
            </NavLink>
          </MDBNavItem>
          {premium === "premium" ? (
            <MDBNavItem>
              <NavLink
                to="#"
                active={this.state.activeItem === "2"}
                onClick={this.toggle("2")}
                role="tab"
                className={
                  this.state.activeItem === "2" ? "nav-link active" : "nav-link"
                }
              >
                <MDBIcon icon="images" /> <span>Imagens</span>
              </NavLink>
            </MDBNavItem>
          ) : (
            ""
          )}

          <MDBNavItem>
            <NavLink
              to="#"
              active={this.state.activeItem === "3"}
              onClick={this.toggle("3")}
              role="tab"
              className={
                this.state.activeItem === "3" ? "nav-link active" : "nav-link"
              }
            >
              <MDBIcon icon="star" /> <span>Reviews</span>
            </NavLink>
          </MDBNavItem>
        </MDBNav>
        <Form
          activeItem={this.state.activeItem}
          adType={adType}
          id={id}
          {...this.props}
        />
      </Container>
    );
  }
}

export default Tabs;
