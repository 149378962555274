import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Rater from "react-rater";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact";

import { AdReviewActions } from "../../../store/ducks/ad-review";

import {
  Container,
  ReviewCard,
  Header,
  Description,
  ReplyContainer,
  NoReviewAlert
} from "./styles";

import * as IMAGES from "../../../assets/images";

class ReviewList extends Component {
  state = {
    id: "",
    modal: false,
    reply: "",
    loadingReply: false
  };

  async componentDidMount() {
    await this.props.getAdReviewRequest(this.props.ad);
  }

  handleEditing = async reviewId => {
    const { modal } = this.state;

    this.setState({ id: reviewId });
    this.setState({ modal: !modal });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { id, reply, modal } = this.state;
    const { ad, getAdReviewRequest, setAdReviewRequest } = this.props;

    this.setState({ modal: !modal });

    await setAdReviewRequest(id, reply);
    await getAdReviewRequest(ad);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleToggle = () => {
    const { modal } = this.state;

    this.setState({ id: "" });
    this.setState({ reply: "" });

    this.setState({ modal: !modal });
  };

  render() {
    const { modal, reply } = this.state;
    const { reviews, loading } = this.props;

    if (loading) {
      return (
        <Container>
          <MDBSpinner />
        </Container>
      );
    }

    return (
      <Container>
        {reviews && reviews.length ? (
          reviews.map(review => (
            <ReviewCard key={review._id}>
              <div className="avatar">
                <img
                  src={review.traveler.profile_pic || IMAGES.AVATAR}
                  alt=""
                />
                <span className="traveler-name">{`${review.traveler.first_name} ${review.traveler.last_name}`}</span>
              </div>
              <div className="content">
                <Header>
                  <div className="title-wrapper">
                    <div className="rating-posted-date">
                      <div className="rating">
                        <Rater
                          rating={review.rating}
                          total={5}
                          interactive={false}
                        />
                      </div>
                      <div className="posted">
                        <span>{review.formatted_posted_date}</span>
                      </div>
                    </div>
                    <span className="title">{review.title}</span>
                  </div>
                </Header>
                <Description>
                  <p>{review.review}</p>
                </Description>
                {review.reply ? (
                  <ReplyContainer>
                    <span className="reply-title">Sua resposta:</span>
                    <p>{review.reply}</p>
                  </ReplyContainer>
                ) : (
                  <ReplyContainer>
                    <div className="reply-button">
                      <MDBBtn
                        onClick={() => this.handleEditing(review._id)}
                        outline
                        color="primary"
                        size="sm"
                      >
                        Responder
                        <MDBIcon icon="edit" className="ml-2" />
                      </MDBBtn>
                    </div>
                  </ReplyContainer>
                )}
              </div>
            </ReviewCard>
          ))
        ) : (
          <NoReviewAlert>Nenhum review foi enviado até o momento</NoReviewAlert>
        )}

        <MDBModal isOpen={modal} toggle={this.handleToggle}>
          <form onSubmit={this.handleSubmit}>
            <MDBModalHeader toggle={this.handleToggle}>
              Review - Resposta
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    label="Escreva sua resposta"
                    type="textarea"
                    rows="3"
                    onChange={this.handleInputChange}
                    name="reply"
                    required
                    value={reply}
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.handleToggle} size="sm">
                Cancelar
              </MDBBtn>
              <MDBBtn color="primary" type="submit" size="sm">
                Enviar
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </Container>
    );
  }
}

ReviewList.defaultProps = {
  reviews: null
};

const mapStateToProps = state => ({
  reviews: state.adReview.data,
  loading: state.adReview.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(AdReviewActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewList);
