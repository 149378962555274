import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  div.buttons {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    @media (max-width: 500px) {
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 10px;
    }

    span {
      margin-left: 10px;
    }

    .btn {
      width: 165px;
    }
  }
`;

export const Panel = styled.div`
  margin: 1px 0;
  width: 100%;
  background: #fff;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 20px 0;

  @media (max-width: 838px) {
    width: 98%;
  }

  form {
    width: 100%;
  }

  div.customer-data {
    width: 100%;
    padding: 0 20px 20px 20px;
  }

  div.info-ad {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px;

    span {
      font-size: 0.85em;
      color: #999999;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin-bottom: 5px;
    }

    p {
      font-size: 0.9em;
      margin-bottom: 5px;
    }
  }

  div.payment-info {
    display: flex;
    width: 100%;
    padding: 20px;

    @media (max-width: 1250px) {
      flex-direction: column;
      padding: 20px 0;
    }

    div.credit-card {
      width: 30%;
      align-items: center;
      justify-content: center;

      @media (max-width: 1250px) {
        width: 100%;
      }
    }

    div.form-container {
      width: 70%;

      @media (max-width: 1250px) {
        width: 100%;
      }

      div.form-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 10px;

        @media (max-width: 500px) {
          flex-direction: column;
        }

        input {
          width: 95%;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          outline: none;
          box-shadow: none;
          border: none;
          border-bottom: 1px solid #ced4da;
          border-radius: 0;
          box-sizing: content-box;
          background-color: transparent;
          margin: 0.5rem;
          padding: 0.6rem 0 0.4rem 0;
          height: auto;
        }
      }

      div.form-button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const Section = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(63, 81, 181, 0.2),
    0px 4px 5px 0px rgba(63, 81, 181, 0.14),
    0px 1px 10px 0px rgba(63, 81, 181, 0.12);

  background: rgb(63, 81, 181);
  color: #fff;
  font-size: 1.2em;
  font-weight: 800;
  letter-spacing: 0.15em;

  span {
    width: 80%;
  }

  svg {
    font-size: 1.5em;
  }

  @media (max-width: 769px) {
    margin-left: 0;

    font-size: 0.85em;
    svg {
      font-size: 1.25em;
    }
  }
`;
