const getAccomodationTypes = type => {
  return [
    {
      index: 0,
      checked: type === "Casa/Apto inteiro",
      value: "Casa/Apto inteiro"
    },
    {
      index: 1,
      checked: type === "Casa/Apto compartilhado",
      value: "Casa/Apto compartilhado"
    },
    {
      index: 2,
      checked: type === "Quarto Compartilhado",
      value: "Quarto Compartilhado"
    },
    {
      index: 3,
      checked: type === "Quarto Privativo",
      value: "Quarto Privativo"
    },
    {
      index: 4,
      checked: type === "Sofá",
      value: "Sofá"
    }
  ];
};

export default getAccomodationTypes;
